import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { ProductsSection1 } from '../sections/products-section1'
import { ProductsSection2 } from '../sections/products-section2'

const Outer = styled.div``

type ProductsPageProps = {

}
export const ProductsPage: FunctionComponent<ProductsPageProps> = () => {
  return <Outer>
    <ProductsSection1 />
    <ProductsSection2 />
  </Outer>
}