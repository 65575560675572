import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { ContactSections1 } from '../sections/contact-sections1'
import { ContactForm } from '../components/contact-form'

const Outer = styled.div``

type ContactPageProps = {

}
export const ContactPage: FunctionComponent<ContactPageProps> = () => {
  return <Outer>
    <ContactSections1 />
  </Outer>
}