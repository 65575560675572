import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { TopHead } from '../components/top-head'
import { lrem } from '../styles/lrem'
import { Button, LinkButton } from '../components/button'
import { pageMaxWidth } from '../styles/page-max-width'
import { pageGrid } from '../styles/page-grid'

const Outer = styled.div`
  margin-top: ${lrem(97)};
  @media (max-width: 40rem){
    margin-top: ${lrem(41)};
  }
`

const Inner = styled.div`
  ${pageMaxWidth}
  box-sizing: border-box;
  padding-left: calc(var(--page-margin) + var(--page-side-margin1) + var(--page-side-margin2));
  padding-top: ${lrem(341 - 97)};
  padding-right: calc(var(--page-margin) + var(--page-side-margin1));
  @media (max-width: 40rem){
    padding-top: ${lrem(30)};
  }
`

const Text1 = styled.div`
  font-size: ${lrem(40)};
  letter-spacing: 0.1em;
  line-height: 2;
  @media (max-width: 40rem){
    font-size: ${lrem(15)};
  }
`

const Text2 = styled.div`
  font-size: ${lrem(25)};
  letter-spacing: 0.1em;
  line-height: 2.4;
  margin-top: ${lrem(60)};
  @media (max-width: 40rem){
    font-size: ${lrem(12)};
    margin-top: ${lrem(30)};
  }
`

const ButtonContainer = styled.div`
  margin-top: ${lrem(160)};
  display: flex;
  justify-content: flex-end;
  @media (max-width: 40rem){
    margin-top: ${lrem(76)};
    justify-content: center;
  }
`

const ImageContainer = styled.div`
  position: relative;
  max-width: calc(var(--page-max-content-width) + var(--page-margin) * 2);
  margin-left: auto;
  margin-right: auto;
  height: ${lrem(1158)};
  margin-top: ${lrem(106)};
  @media (max-width: 40rem){
    ${pageGrid}
    margin-top: ${lrem(57)};
    grid-template-rows: min-content min-content;
    height: auto;
    margin-bottom: ${lrem(69)};
  }
`

const Image1 = styled.img`
  position: absolute;
  left: ${lrem(460)};
  top: 0;
  width: ${lrem(628)};
  height: ${lrem(419)};
  @media (max-width: 40rem){
    position: static;
    top: auto;
    width: auto;
    height: auto;
    grid-column: 1 / 5;
    grid-row: 2;
    min-width: 0;
    width: 100%;
  }
`

const Image2 = styled.img`
  position: absolute;
  right: 0;
  top: ${lrem(330)};
  width: ${lrem(570)};
  height: ${lrem(380)};
  @media (max-width: 40rem){
    position: static;
    top: auto;
    width: auto;
    height: auto;
    grid-column: 5 / 11;
    grid-row: 2;
    min-width: 0;
    width: 100%;
  }
`

const Image3 = styled.img`
  position: absolute;
  left: 0;
  top: ${lrem(595)};
  width: ${lrem(842)};
  height: ${lrem(563)};
  @media (max-width: 40rem){
    position: static;
    top: auto;
    width: auto;
    height: auto;
    grid-column: 2 / 8;
    grid-row: 1;
    min-width: 0;
    width: 100%;
    margin-bottom: ${lrem(83)};
  }
`

type TopAboutSectionsProps = {

}
export const TopAboutSections: FunctionComponent<TopAboutSectionsProps> = () => {
  return <Outer>
    <TopHead withBar>
      ABOUT US
    </TopHead>
    <Inner>
      <Text1>
        マリンソフトは、海上自衛隊が保有する艦船の安全航行と<br />
        維持整備活動の支援を目的として、<br />
        幅広いエンジニアリングサービス事業を展開しています。
      </Text1>
      <Text2>
        Marine soft Co., Ltd. is engaged in a wide range of engineering services to support the safe navigation and maintenance activities of ships owned by the Maritime Self-Defense Force.
      </Text2>
      <ButtonContainer>
        <LinkButton to="/about">ABOUT US &gt;</LinkButton>
      </ButtonContainer>
    </Inner>
    <ImageContainer>
      <Image1 src="/images/0001.jpg" width="628" height="419" />
      <Image2 src="/images/0002.jpg" width="570" height="380" />
      <Image3 src="/images/0003.jpg" width="842" height="563" />
    </ImageContainer>
  </Outer>
}