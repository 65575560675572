import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import styled, { createGlobalStyle } from 'styled-components'
import SvgCloseIcon from '../generated/svg/close-icon'
import { lrem } from '../styles/lrem'

const Outer = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 100;
  display: grid;
  place-items: center;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.4s;
  &.show {
    opacity: 1;
    pointer-events: all;
  }
`

const ButtonContainer = styled.button`
  padding: ${lrem(10)};
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  display: block;
  svg {
    display: block;
    width: ${lrem(18)};
    height: auto;
  }
`

const Inner = styled.div``

const List = styled.div`
  display: flex;
  flex-direction: column;
`

const Item = styled(Link)`
  display: block;
  padding: ${lrem(20)};
  text-align: center;
  font-weight: 700;
  font-size: ${lrem(16)};
  color: var(--main-color);
`

const PreventScroll = createGlobalStyle`
  :root {
    overflow: hidden;
  }
`

type MobileMenuProps = {
  show: boolean
  onClose: () => void
}
export const MobileMenu: FunctionComponent<MobileMenuProps> = ({show, onClose}) => {
  return <Outer className={show ? 'show' : ''}>
    {
      show && <PreventScroll />
    }
    <ButtonContainer onClick={() => onClose()}>
      <SvgCloseIcon />
    </ButtonContainer>
    <Inner>
      <List>
        <Item to="/" onClick={onClose}>TOP</Item>
        <Item to="/about" onClick={onClose}>ABOUT US</Item>
        <Item to="/features" onClick={onClose}>FEATURES</Item>
        <Item to="/products" onClick={onClose}>PRODUCTS & SERVICES</Item>
        <Item to="/voices" onClick={onClose}>VOICE</Item>
        <Item to="/recruit" onClick={onClose}>RECRUIT</Item>
        <Item to="/contact" onClick={onClose}>CONTACT</Item>
      </List>
    </Inner>

  </Outer>
}