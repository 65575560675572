import React, { FunctionComponent, useEffect, useState } from 'react'
import styled from 'styled-components'
import SvgPageTopPc from '../generated/svg/page-top-pc'
import { lrem } from '../styles/lrem'
import { useBreakpoint } from '../hooks/use-breakpoint'
import SvgPageTopMobile from '../generated/svg/page-top-mobile'

const Outer = styled.div`
  position: fixed;
  bottom: ${lrem(40)};
  right: ${lrem(40)};
  cursor: pointer;
  z-index: 100;
  transition: opacity 0.4s;
  @media (max-width: 40rem){
    right: ${lrem(10)};
    bottom: ${lrem(10)};
  }
  svg {
    display: block;
    width: ${lrem(44)};
    height: auto;
    @media (max-width: 40rem){
      width: ${lrem(16)};
    }
  }
`

type ScrollToTopButtonProps = {

}
export const ScrollToTopButton: FunctionComponent<ScrollToTopButtonProps> = () => {
  const [hide, setHide] = useState(false)
  const isMobile = useBreakpoint('40rem')
  useEffect(() => {
    const listener = () => {
      const scrollY = window.scrollY
      if(scrollY === 0){
        setHide(true)
      } else {
        setHide(false)
      }
    }
    listener()
    window.addEventListener('scroll', listener)
    return () => {
      window.removeEventListener('scroll', listener)
    }
  }, [])
  return <Outer style={{opacity: hide ? '0' : '1'}} onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>
    {
      isMobile ?
      <SvgPageTopMobile /> :
      <SvgPageTopPc/>
    }
  </Outer>
}