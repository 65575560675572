import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { VoicesSection1 } from '../sections/voices-section1'
import { VoicesSection2 } from '../sections/voices-section2'
import { VoicesSection3 } from '../sections/voices-section3'
import { VoicesSection4 } from '../sections/voices-section4'

const Outer = styled.div``

type VoicesPageProps = {

}
export const VoicesPage: FunctionComponent<VoicesPageProps> = () => {
  return <Outer>
    <VoicesSection1 />
    <VoicesSection2 />
    <VoicesSection3 />
    <VoicesSection4 />
  </Outer>
}