import React, { FunctionComponent } from 'react'
import styled, { keyframes } from 'styled-components'
import { lrem } from '../styles/lrem'
import { AnimatedText } from '../components/animated-text'
import { Mbr } from '../components/br'

const Outer = styled.div`
  height: ${lrem(1280)};
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 40rem){
    margin-top: var(--header-height);
    height: ${lrem(490)};
  }
`

const VideoContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  video {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const TextContainer = styled.div`

`

const fadeIn = keyframes`
  from {
    filter: blur(2rem);
    transform: skewX(10deg);
    opacity: 0;
  }
  to {
    filter: none;
    transform: none;
    opacity: 1;
  }
`

const slide = keyframes`
  from {
    margin-top: 0;
  }
  to {
    margin-top: ${lrem(120)};
  }
`

const Text1 = styled.div`
  color: #fff;
  font-size: ${lrem(250)};
  letter-spacing: 0.025em;
  font-weight: 700;
  animation: ${fadeIn} 2s 2s both, ${slide} 2s 4s both;
  @media (max-width: 40rem){
    font-size: ${lrem(60)};
    animation: ${fadeIn} 2s 2s both;
  }
  small {
    font-size: ${lrem(150)};
    @media (max-width: 40rem){
      font-size: ${lrem(37)};
    }
  }
`

const Text2 = styled.div`
  color: #fff;
  font-size: ${lrem(43)};
  letter-spacing: 0.1em;
  margin-top: ${lrem(60)};
  @media (max-width: 40rem){
    margin-top: ${lrem(44)};
    font-size: ${lrem(16)};
    line-height: 1.666;
  }
`

const Text3 = styled.div`
  color: #fff;
  font-size: ${lrem(28)};
  letter-spacing: 0.045em;
  margin-top: ${lrem(30)};
  @media (max-width: 40rem){
    margin-top: ${lrem(12)};
    font-size: ${lrem(14)};
    line-height: 1.3;
  }
`

type TopBannerSectionProps = {

}
export const TopBannerSection: FunctionComponent<TopBannerSectionProps> = () => {
  return <Outer>
    <VideoContainer>
      <video src="/videos/0001.mp4" width={1920} height={1080} autoPlay loop muted playsInline />
    </VideoContainer>
    <TextContainer>
      <Text1>
        TRUST<br />
        <small>in the </small>BLUE
      </Text1>
      <Text2>
        <AnimatedText delay={750} speed={25}>
          青い海での使命を担う<Mbr />お客様の信頼にお応えするために
        </AnimatedText>
      </Text2>
      <Text3>
        <AnimatedText delay={750} speed={7}>
          To respond to the trust of our customers <Mbr />who carry out their mission in the blue sea.
        </AnimatedText>
      </Text3>
    </TextContainer>
  </Outer>
}