import * as React from "react";
import type { SVGProps } from "react";
const SvgCloseIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={14}
    data-name="\u30EC\u30A4\u30E4\u30FC 1"
    viewBox="0 0 14 14"
    {...props}
  >
    <g fill="currentcolor" data-name="Group 1437">
      <path
        d="m12.146 12.854-11-11 .708-.708 11 11-.708.708Z"
        data-name="Line 313"
      />
      <path
        d="m1.854 12.854-.707-.707 11-11 .707.707-11 11Z"
        data-name="Line 345"
      />
    </g>
  </svg>
);
export default SvgCloseIcon;
