import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { lrem } from '../styles/lrem'
import { pageGrid } from '../styles/page-grid'
import { gridBackground } from '../styles/grid-background'
import { useBreakpoint } from '../hooks/use-breakpoint'

const Outer = styled.div`
  ${pageGrid}
  padding-bottom: ${lrem(425)};
  @media (max-width: 40rem){
    padding-bottom: ${lrem(80)};
  }
`

const Section = styled.div`
  grid-column: 4 / 11;
  padding: calc();
  position: relative;
  padding-top: ${lrem(116)};
  padding-bottom: ${lrem(200)};
  @media (max-width: 40rem){
    padding-top: ${lrem(60)};
    padding-bottom: ${lrem(60)};
  }
  &:nth-child(2n) {
    &::before {
      content: '';
      display: block;
      width: calc(100% + ${lrem(80)});
      height: 100%;
      position: absolute;
      top: 0;
      left: ${lrem(-80)};
      ${gridBackground}
    }
  }
`

const SectionInner = styled.div`
  width: calc(var(--page-side-content-width) * 2 + var(--page-center-margin) * 2 + var(--page-side-margin2));
  position: relative;

`

const HeadContainer = styled.div`
  display: flex;
  gap: ${lrem(50)};
  margin-bottom: ${lrem(40)};
  @media (max-width: 40rem){
    gap: ${lrem(20)};
    margin-bottom: ${lrem(20)};
  }
`

const HeadInnerLeft = styled.div``

const HeadInnerRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const Num = styled.div`
  font-size: ${lrem(230)};
  color: var(--main-color);
  font-family: 'Lato';
  font-weight: 100;
  @media (max-width: 40rem){
    font-size: ${lrem(60)};
  }
`

const Head1 = styled.div`
  font-weight: 700;
  font-size: ${lrem(60)};
  letter-spacing: 0.025em;
  color: var(--main-color);
  @media (max-width: 40rem){
    font-size: ${lrem(15)};
  }
  span {
    display: block;
    margin-top: ${lrem(20)};
    font-size: ${lrem(18)};
    @media (max-width: 40rem){
      margin-top: ${lrem(5)};
      font-size: ${lrem(9)};
    }
  }
`

const Head2 = styled.div`
  font-size: ${lrem(40)};
  font-weight: 300;
  color: var(--main-color);
  margin-top: ${lrem(30)};
  @media (max-width: 40rem){
    font-size: ${lrem(20)};
    margin-top: ${lrem(10)};
  }
`

const Text3 = styled.div`
  font-size: ${lrem(38)};
  font-weight: 400;
  margin-bottom: ${lrem(40)};
  @media (max-width: 40rem){
    font-size: ${lrem(18)};
    margin-bottom: ${lrem(20)};
  }
  span {
    font-size: ${lrem(32)};
    font-weight: 300;
    @media (max-width: 40rem){
      font-size: ${lrem(16)};
      display: block;
      margin-top: ${lrem(10)};
    }
  }
`

const Text1 = styled.div`
  font-size: ${lrem(26)};
  line-height: 2.5;
  @media (max-width: 40rem){
    font-size: ${lrem(13)};
  }
`

const HorizontalContainer = styled.div`
  display: flex;
  margin-top: ${lrem(60)};
  @media (max-width: 40rem){
    flex-direction: column;
    margin-top: ${lrem(30)};
  }
`

const ImageContainer = styled.div`
  flex: 1 1 0;
  @media (max-width: 40rem){
    order: 10;
    margin-top: ${lrem(40)};
  }
  img {
    display: block;
    width: 100%;
    height: auto;
  }
`

const ImageNote = styled.div`
  font-size: ${lrem(16)};
  letter-spacing: 0.02em;
  margin-top: ${lrem(20)};
  @media (max-width: 40rem){
    font-size: ${lrem(10)};
    margin-top: ${lrem(10)};
  }
`

const Text2 = styled.div`
  font-size: ${lrem(21)};
  letter-spacing: 0.025em;
  line-height: 2.14285714286;
  @media (max-width: 40rem){
    font-size: ${lrem(13)};
  }
`

const Text2Left = styled(Text2)`
  flex: 0 0 calc(var(--page-side-content-width) + var(--page-center-margin) * 2);
  margin-right: var(--page-center-margin);
`

const Text2Right = styled(Text2)`
  flex: 0 0 calc(var(--page-side-content-width) + var(--page-side-margin2));
  margin-left: var(--page-center-margin);
`

const Text2Center = styled(Text2)`
  margin-top: ${lrem(20)};
  margin-bottom: ${lrem(80)};
  @media (max-width: 40rem){
    margin-bottom: ${lrem(60)};
  }
`

const Inner = styled.div`
  border: 1px solid currentColor;
  background-color: #fff;
  padding: ${lrem(40)};
  @media (max-width: 40rem){
    padding: ${lrem(10)};
    padding-bottom: ${lrem(40)};
  }
`

const InnerImageContainer = styled.div`
  img {
    display: block;
    width: 100%;
    height: auto;
  }
`

const InnerText1 = styled.div`
  font-size: ${lrem(25)};
  letter-spacing: 0.05em;
  margin-top: ${lrem(40)};
  @media (max-width: 40rem){
    font-size: ${lrem(16)};
  }
  span {
    @media (max-width: 40rem){
      font-size: ${lrem(12)};
      display: block;
      margin-top: ${lrem(10)};
    }
  }
`

const InnerText2 = styled.div`
  font-size: ${lrem(18)};
  letter-spacing: 0.025em;
  line-height: 1.94;
  margin-top: ${lrem(20)};
  @media (max-width: 40rem){
    font-size: ${lrem(13)};
  }
`

const InnerText3 = styled.div`
  font-size: ${lrem(18)};
  letter-spacing: 0.025em;
  line-height: 1.94;
  margin-top: ${lrem(10)};
  @media (max-width: 40rem){
    font-size: ${lrem(13)};
  }
`

type ProductsSection2Props = {

}
export const ProductsSection2: FunctionComponent<ProductsSection2Props> = () => {
  const isMobile = useBreakpoint('40rem')
  return <Outer>
    <Section id="1">
      <SectionInner>
        <HeadContainer>
          <HeadInnerLeft>
            <Num>01</Num>
          </HeadInnerLeft>
          <HeadInnerRight>
            <Head1>System Documentation</Head1>
            <Head2>システム図書</Head2>
          </HeadInnerRight>
        </HeadContainer>
        <Text1>
          艦船の構造や装置について解説した技術図書のほか、安全航行に必要とされる取扱説明書などを制作しています。正確な情報であることはもちろんのこと、図表やイラスト、アニメーション等を効果的に用いることで、使用するお客様が効率よく理解できるように工夫を凝らしています。
        </Text1>
        <HorizontalContainer>
          <ImageContainer>
            <img src="/images/0015.jpg" width={1180} height={741}/>
          </ImageContainer>
          <Text2Right>
            In addition to technical books that explain the structure and equipment of ships, we also produce instruction manuals that are necessary for safe navigation. In addition to providing accurate information, we have made effective use of charts, illustrations, animations, etc., to ensure that the customers who use the system can understand it efficiently.
          </Text2Right>
        </HorizontalContainer>
      </SectionInner>
    </Section>
    <Section id="2">
      <SectionInner>
        <HeadContainer>
          <HeadInnerLeft>
            <Num>02</Num>
          </HeadInnerLeft>
          <HeadInnerRight>
            <Head1>Parts Book</Head1>
            <Head2>パーツブック</Head2>
          </HeadInnerRight>
        </HeadContainer>
        <Text1>
          艦船に装備されている機器を部品単位まで細部に図解した分解図と部品表などで構成される技術図書です。部品の修理・交換が必要になった際に当該部品の情報を調べるのに活用されるほか、機器の維持修理に必要な部品の準備計画を立てる資料としても使用されます。
        </Text1>
        <HorizontalContainer>
          <Text2Left>
            A technical book consisting of exploded views and parts lists that illustrate the equipment installed on ships in detail down to the component level. In addition to being used to check the information of the part when it needs to be repaired or replaced, it is also used as a document for planning to prepare the parts necessary for maintenance and repair of the equipment.
          </Text2Left>
          <ImageContainer>
            <img src="/images/0016.jpg" width={1189} height={835}/>
          </ImageContainer>
        </HorizontalContainer>
      </SectionInner>
    </Section>
    <Section id="3">
      <SectionInner>
        <HeadContainer>
          <HeadInnerLeft>
            <Num>03</Num>
          </HeadInnerLeft>
          <HeadInnerRight>
            <Head1>Production Specifications</Head1>
            <Head2>生産明細書</Head2>
          </HeadInnerRight>
        </HeadContainer>
        <Text1>
          艦船を構成する材料・部品・装備品等について、国有財産（船体及び固定装備品など）と艦船搭載物品（予備品・要具など）の区分を明らかにする図書であり、艦船の建造完了時における納品書の一部として使用されるとともに国有財産台帳登記に使用される資料となります。
        </Text1>
        <HorizontalContainer>
          <ImageContainer>
            <img src="/images/0017.jpg" width={1161} height={897}/>
            <ImageNote>画像提供: 海上自衛隊</ImageNote>
          </ImageContainer>
          <Text2Right>
            This is a book that clarifies the classification of materials, parts, equipment, etc. that make up a ship into national property (hull, fixed equipment, etc.) and shipboard materials (spare parts, tools, etc.), and is used as part of the delivery notes at the completion of ship construction and as a document used for registration in the national property register.
          </Text2Right>
        </HorizontalContainer>
      </SectionInner>
    </Section>
    <Section id="4">
      <SectionInner>
        <HeadContainer>
          <HeadInnerLeft>
            <Num>04</Num>
          </HeadInnerLeft>
          <HeadInnerRight>
            <Head1>Data Analysis</Head1>
            <Head2>整備管理／状態監視</Head2>
          </HeadInnerRight>
        </HeadContainer>
        <Text3>Maintenance Management Business <span>整備管理事業</span></Text3>
        <Text1>
          艦船の定期的検査を効果的かつ経済的に行うために、就役後の整備情報をデータベース化。整備来歴や故障等の不具合情報、機関の運転情報等の分析・評価を行うことで、次回検査の効率化、検査担当監督官の業務軽減に貢献しています。
        </Text1>
        <Text2Center>
          In order to conduct periodical survey of ships effectively and economically, maintenance information after commissioning is compiled into a database. By analyzing and evaluating the history of maintenance, information on malfunctions and other defects, engine operation information, etc., the database contribute to improving the efficiency of the next survey and reducing the workload of the surveyors in charge of the inspection.
        </Text2Center>
        <Text3>Condition Monitoring Analysis <span>状態監視事業</span></Text3>
        <Text1>
          自動計測等された各種ポンプの振動データを定期的に監視したうえで分析・評価を実施。これにより定期的なオーバーホールからオンコンデションでの整備が可能となり、臨時修理の削減による可動率の向上及び修理予算の削減に寄与しています。
        </Text1>
        <Text2Center>
          Vibration data of various pumps, which is automatically measured, are periodically monitored and then analyzed and evaluated. This enables periodic overhauls and on-condition maintenance, which contributes to the improvement of availability and reduction of the repair cost by reducing temporary repairs.
        </Text2Center>
        <Inner>
          <InnerImageContainer>
            {
              isMobile ?
              <img src="/images/0024.jpg" width={296} height={382}/> :
              <img src="/images/0018.jpg" width={2450} height={754}/>
            }
          </InnerImageContainer>
          <InnerText1>
            ■ 状態監視整備方式  <span>Condition monitoring maintenance method</span>
          </InnerText1>
          <InnerText2>
            機器の作動状態（振動等）を定期的に監視して使用を継続、監視値が一定のしきい値を超えた場合、当該危機が故障に至る前に修理を実施。
          </InnerText2>
          <InnerText3>
            A maintenance method in which the equipment’s operating condition (vibration, etc.) is constantly monitored for continued use. <br />
            If the monitored value exceeds a certain threshold, repairs will be carried out before the crisis leads to failure.
          </InnerText3>
        </Inner>
      </SectionInner>
    </Section>
    <Section id="5">
      <SectionInner>
        <HeadContainer>
          <HeadInnerLeft>
            <Num>05</Num>
          </HeadInnerLeft>
          <HeadInnerRight>
            <Head1>Digitalization</Head1>
            <Head2>電子化</Head2>
          </HeadInnerRight>
        </HeadContainer>
        <Text1>
          完成図書を中心とする各種技術文書等をデジタルデータへと変換。電子媒体に集約するとともに、必要に応じてネットワークへの登録を行っています。紙メディアの電子化によって、情報の共有化や利便性の向上、関連作業の合理化を推進し、トータルコストの削減と整備性の向上に寄与します。
        </Text1>
        <HorizontalContainer>
          <Text2Left>
            Various technical documents, mainly technical documents, are converted into digital data. Information is collected in electronic media and registered on the network as necessary. By digitizing paper media, we promote the sharing of information, the improvement of convenience, the rationalization of related work, and contribute to the reduction of total costs and the improvement of maintainability.
          </Text2Left>
          <ImageContainer>
            <img src="/images/0019.jpg" width={1045} height={751}/>
          </ImageContainer>
        </HorizontalContainer>
      </SectionInner>
    </Section>
    <Section id="6">
      <SectionInner>
        <HeadContainer>
          <HeadInnerLeft>
            <Num>06</Num>
          </HeadInnerLeft>
          <HeadInnerRight>
            <Head1>
              COSAL Works<br />
              <span>[ Coordinated Shipboard Allowance List ]</span>
            </Head1>
            <Head2>コーサル作業</Head2>
          </HeadInnerRight>
        </HeadContainer>
        <Text1>
          任務行動中に発生する装置の故障に対処するため、各種装置の予備品や付属品・要具は艦内または陸上施設に保管しておく必要があります。そのために各種装置の情報を基にこれらの予備品や付属品等を艦内保管品と陸上保管品に整理・仕分けを実施し、最終的に艦内の決められた場所に搭載・格納または陸上保管施設に輸送する手続きを行っています。
        </Text1>
        <HorizontalContainer>
          <ImageContainer>
            <img src="/images/0020.jpg" width={1183} height={758}/>
            <ImageNote>写真提供: 海上自衛隊</ImageNote>
          </ImageContainer>
          <Text2Right>
            In order to deal with equipment malfunctions that occur during mission operations, spare parts, accessories, and tools for various devices must be stored onboard the ship or in shore facilities. Based on information on the various types of equipment, these spare parts and accessories are organized and sorted into onboard and shore-based storage items, and are finally mounted and stored in specified locations onboard the ship or sending them to the shore facilities.
          </Text2Right>
        </HorizontalContainer>
      </SectionInner>
    </Section>
  </Outer>
}