import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { lrem } from '../styles/lrem'

const Outer = styled.div`
  margin-top: ${lrem(230)};
  padding-bottom: ${lrem(384)};
  @media (max-width: 40rem){
    margin-top: ${lrem(60)};
    padding-bottom: ${lrem(80)};
  }
`

const Container = styled.div`
  display: flex;
  @media (max-width: 40rem){
    flex-direction: column;
  }
  img {
    width: 50%;
    height: auto;
    display: block;
    @media (max-width: 40rem){
      width: 100%;
    }
  }
`

type VoicesSection4Props = {

}
export const VoicesSection4: FunctionComponent<VoicesSection4Props> = () => {
  return <Outer>
  </Outer>
}