import React from 'react';
import { FunctionComponent } from 'react';
import { ResetCss } from './components/reset-css';
import { RootCss } from './components/root-css';
import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache } from '@apollo/client';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { ScrollToTop } from './components/scroll-to-top';
import styled from 'styled-components';
import { Header } from './components/header';
import { Footer } from './components/footer';
import { TopPage } from './pages/top-page';
import { AboutPage } from './pages/about-page';
import { ContactPage } from './pages/contact-page';
import { FeaturesPage } from './pages/features-page';
import { ProductsPage } from './pages/products-page';
import { RecruitPage } from './pages/recruit-page';
import { VoicesPage } from './pages/voices-page';
import { RestoreScroll } from './components/restore-scroll';
import { ScrollToTopButton } from './components/scroll-to-top-button';

const apolloClient = new ApolloClient({
  link: new HttpLink({
    uri: 'https://www.marine-soft.co.jp/api/index.php?graphql',
    fetchOptions: {
      mode: 'cors'
    }
  }),
  cache: new InMemoryCache()
})

const Outer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`
const HeaderOuter = styled.div``
const ContentOuter = styled.div`
  flex: 1 1 auto;
`
const FooterOuter = styled.div``

export const App: FunctionComponent = () => {
  return <>
    <ApolloProvider client={apolloClient}>
      <BrowserRouter>
        <ScrollToTop />
        <RestoreScroll />
        <ResetCss />
        <RootCss />
        <Outer>
          <HeaderOuter>
            <Header />
          </HeaderOuter>
          <ContentOuter>
            <Routes>
              <Route index element={<TopPage />} />
              <Route path="about" element={<AboutPage />} />
              <Route path="contact" element={<ContactPage />} />
              <Route path="features" element={<FeaturesPage />} />
              <Route path="products" element={<ProductsPage />} />
              <Route path="recruit" element={<RecruitPage />} />
              <Route path="voices" element={<VoicesPage />} />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </ContentOuter>
          <FooterOuter>
            <Footer />
          </FooterOuter>
          <ScrollToTopButton />
        </Outer>
      </BrowserRouter>
    </ApolloProvider>
  </>
}