import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { TopBannerSection } from '../sections/top-banner'
import { TopAboutSections } from '../sections/top-about-sections'
import { TopFeaturesSection } from '../sections/top-features-section'
import { TopProductsSection } from '../sections/top-products-section'
import { TopVoicesSection } from '../sections/top-voices-section'

const Outer = styled.div``

const BannerContainer = styled.div``

type TopPageProps = {

}
export const TopPage: FunctionComponent<TopPageProps> = () => {
  return <Outer>
    <BannerContainer>
      <TopBannerSection />
    </BannerContainer>
    <TopAboutSections />
    <TopFeaturesSection />
    <TopProductsSection />
    <TopVoicesSection />
  </Outer>
}