import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { pageGrid } from '../styles/page-grid'
import { lrem } from '../styles/lrem'
import { Dbr } from '../components/br'

const Outer = styled.div`
`

const Section = styled.div`
  ${pageGrid}
  padding-top: ${lrem(150)};
  padding-bottom: ${lrem(150)};
  @media (max-width: 40rem){
    padding-top: ${lrem(54)};
    padding-bottom: ${lrem(105)};
  }
  &:nth-child(even){
    background-color: #ECF6F9;
  }
  &:last-child {
    padding-bottom: ${lrem(400)};
    @media (max-width: 40rem){
      padding-bottom: ${lrem(100)};
    }
  }
`

const Inner = styled.div`
  grid-column: 4 / 9;
`

const Head1 = styled.div`
  font-size: ${lrem(50)};
  color: var(--main-color);
  letter-spacing: 0.05em;
  @media (max-width: 40rem){
    font-size: ${lrem(22)};
  }
`

const Head2 = styled.div`
  font-size: ${lrem(35)};
  letter-spacing: 0.025em;
  color: var(--main-color);
  margin-top: ${lrem(15)};
  @media (max-width: 40rem){
    font-size: ${lrem(18)};
    margin-top: ${lrem(10)};
  }
`

const Table = styled.div`
  display: grid;
  grid-template-columns: min-content 1.1fr 0.9fr;
  margin-top: ${lrem(23)};
  padding-top: ${lrem(93)};
  border-top: ${lrem(10)} solid var(--main-color);
  @media (max-width: 40rem){
    border-top-width: ${lrem(3)};
    margin-top: ${lrem(12)};
    padding-top: ${lrem(37)};
    grid-template-columns: min-content 1fr;
  }
`

const Cell = styled.div`
  margin-bottom: ${lrem(30)};
  display: flex;
  align-items: center;
  position: relative;
  @media (max-width: 40rem){
    margin-bottom: ${lrem(20)};
  }
  &::before {
    content: '';
    display: none;
    height: 1px;
    width: 100%;
    position: absolute;
    left: 0;
    top: ${lrem(-30)};
    background-color: var(--text-color);
  }
  &:nth-child(n + 4) {
    margin-top: ${lrem(30)};
    @media (max-width: 40rem){
      margin-top: 0;
    }
    &::before {
      display: block;
      @media (max-width: 40rem){
        display: none;
      }
    }
  }
  @media (max-width: 40rem){
    &:nth-child(3n + 3) {
      margin-bottom: ${lrem(30)};
    }
    &:nth-child(3n + 4),
    &:nth-child(3n + 5) {
      margin-top: ${lrem(30)};
      &::before {
        display: block;
        @media (max-width: 40rem){
          display: block;
        }
      }
    }
  }
`

const Num = styled(Cell)`
  grid-column: 1;
  font-size: ${lrem(120)};
  font-family: 'Lato';
  font-weight: 300;
  text-align: center;
  color: var(--main-color);
  justify-content: center;
  padding-right: ${lrem(40)};
  @media (max-width: 40rem){
    font-size: ${lrem(50)};
    padding-right: ${lrem(20)};
    align-self: flex-start;
  }
`

const Text1 = styled(Cell)`
  grid-column: 2;
  font-size: ${lrem(26)};
  line-height: 2;
  letter-spacing: 0.1em;
  padding-right: ${lrem(30)};
  @media (max-width: 40rem){
    font-size: ${lrem(13)};
    padding-right: 0;
  }
`

const Text2 = styled(Cell)`
  grid-column: 3;
  font-size: ${lrem(21)};
  letter-spacing: 0.01em;
  line-height: 1.8;
  padding-left: ${lrem(30)};
  border-left: 1px solid #B7B7B7;
  @media (max-width: 40rem){
    font-size: ${lrem(13)};
    grid-column: 2;
    padding: 0;
    border-left: 0 none;
  }
`


type FeaturesSection3Props = {

}
export const FeaturesSection3: FunctionComponent<FeaturesSection3Props> = () => {
  return <Outer>
    <Section id="2">
      <Inner>
        <Head1>企業理念</Head1>
        <Head2>CORPORATE PHILOSOPHY</Head2>
        <Table>
          <Num>1</Num>
          <Text1>
            社会から必要とされる製品・サービスを提供し、<Dbr />
            社会の発展および 経済的価値の創出に貢献する。
          </Text1>
          <Text2>
            Provide products and services needed by society, and contribute to the development of society and the creation of economic value.
          </Text2>
          <Num>2</Num>
          <Text1>
            常に先を見据え、<Dbr />
            永く皆様から愛され信頼される企業を目指す。
          </Text1>
          <Text2>
            Always look ahead to the future, and aim to become a company that is loved and trusted by all for years to come.
          </Text2>
          <Num>3</Num>
          <Text1>
            企業活動を通じて、お客様、取引先および社員と<Dbr />
            その家族の幸せに寄与する。
          </Text1>
          <Text2>
            Contribute to the happiness of our customers, our business partners,  our employees and their families through our corporate activities.</Text2>
          <Num>4</Num>
          <Text1>
            他社とは異なる<Dbr />
            独自性のあるポジションを目指す。
          </Text1>
          <Text2>
            Aim to occupy a unique position that differentiates us from other companies.
          </Text2>
          <Num>5</Num>
          <Text1>
            これらを実現するために、<Dbr />
            業績の向上に努める。
          </Text1>
          <Text2>
            To achieve these goals, we will endeavor to improve company performance.
          </Text2>
        </Table>
      </Inner>
    </Section>
    <Section id="3">
      <Inner>
        <Head1>行動指針</Head1>
        <Head2>CODE OF CONDUCT</Head2>
        <Table>
          <Num>1</Num>
          <Text1>
            お客様と信頼関係を築き、<Dbr />
            お客様からの信頼を大切にすること。
          </Text1>
          <Text2>
            Build a relationship based on trust with our customers,  and cherish the trust that we have earned.
          </Text2>
          <Num>2</Num>
          <Text1>
            不断の努力で<Dbr />
            自らを強くすること。
          </Text1>
          <Text2>
            Make ceaseless efforts to gain strength.
          </Text2>
          <Num>3</Num>
          <Text1>
            「和」を大切にし、<Dbr />
            「和」をもって活力を生み出すこと。
          </Text1>
          <Text2>
            Value teamwork, and leverage teamwork to drive momentum.
          </Text2>
          <Num>4</Num>
          <Text1>
            良識と高い倫理観を持って<Dbr />
            行動すること。
          </Text1>
          <Text2>
            Act with integrity and high ethics.
          </Text2>
          <Num>5</Num>
          <Text1>
            未来を創造するために、<Dbr />
            常に新しいことに挑戦すること。
          </Text1>
          <Text2>
            Always take on new challenges to create the future.
          </Text2>
        </Table>
      </Inner>
    </Section>
  </Outer>
}