import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { TopHead } from '../components/top-head'
import { lrem } from '../styles/lrem'
import { pageGrid } from '../styles/page-grid'
import { Link } from 'react-router-dom'

const Outer = styled.div`
  padding-top: ${lrem(181)};
  padding-bottom: ${lrem(289)};
  @media (max-width: 40rem){
    padding-top: calc(var(--header-height) + ${lrem(44)});
    position: relative;
    padding-bottom: 0;
  }
`

const Inner1 = styled.div`
  ${pageGrid}
`

const Head1 = styled.div`
  font-size: ${lrem(50)};
  letter-spacing: 0.05em;
  color: var(--main-color);
  grid-column: 4 / 6;
  font-weight: 700;
  margin-bottom: ${lrem(100)};
  @media (max-width: 40rem){
    font-size: ${lrem(15)};
    margin-bottom: ${lrem(80)};
    margin-top: ${lrem(15)};
    grid-column: 4 / 9;
    text-align: center;
    margin-bottom: 0;
    height: 0;
  }
`

const Inner2 = styled.div`
  position: relative;
  grid-column: 1 / 11;
  ${pageGrid}
  @media (max-width: 40rem){
    position: static;
  }
`

const Inner3 = styled.div`
  grid-row: 2;
  grid-column: 2 / 10;
  display: flex;
  justify-content: center;
  padding: ${lrem(106)} 0;
  @media (max-width: 40rem){
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: ${lrem(40)} 0;
  }
`

const BackgroundVideoContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  @media (max-width: 40rem){
    top: 0;
  }
  video {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const Item = styled(Link)`
  --background-color: #fff;
  width: ${lrem(200)};
  height: ${lrem(200)};
  background-color: var(--background-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  position: relative;
  transition: background-color 0.4s, color 0.4s;
  @media (max-width: 40rem){
    width: ${lrem(120)};
    height: ${lrem(120)};
    margin: ${lrem(10)} ${lrem(20)};
  }
  &::before,
  &::after {
    content: '';
    display: block;
    background-color: var(--background-color);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    z-index: -1;
    transition: background-color 0.4s, color 0.4s;
    @media (max-width: 40rem){
      display: none;
    }
  }
  &::before {
    opacity: 0.5;
    transform: scale(130%);
  }
  &::after {
    opacity: 0.25;
    transform: scale(160%);
  }
  &:nth-child(2n){
    margin-top: ${lrem(225)};
    @media (max-width: 40rem){
      margin-top: ${lrem(10)};
    }
  }
  &:hover {
    --background-color: var(--main-color);
    color: white;
  }
`

const Text1 = styled.div`
  font-size: ${lrem(15)};
  letter-spacing: 0.035em;
  line-height: 1.3888888888;
  font-weight: 300;
  font-family: 'Lato';
  text-align: center;
  @media (max-width: 40rem){
    font-size: ${lrem(10)};
    height: ${lrem(38)};
    display: flex;
    align-items: center;
  }
`

const Text2 = styled.div`
  font-size: ${lrem(16)};
  font-weight: 300;
  margin-top: ${lrem(8)};
  @media (max-width: 40rem){
    font-size: ${lrem(10)};
    margin-top: 0;
  }
`

const Num = styled.div`
  font-size: ${lrem(60)};
  font-weight: 100;
  font-family: 'Lato';
  margin-top: ${lrem(10)};
  @media (max-width: 40rem){
    font-size: ${lrem(30)};
  }
`

type ProductsSection1Props = {

}
export const ProductsSection1: FunctionComponent<ProductsSection1Props> = () => {
  return <Outer>
    <TopHead>PRODUCTS & SERVICES</TopHead>
    <Inner1>
      <Head1>製品&サービス</Head1>
      <Inner2>
        <BackgroundVideoContainer>
          <video src="/videos/0002.mp4" width={1920} height={1080} autoPlay loop muted playsInline />
        </BackgroundVideoContainer>
        <Inner3>
          <Item to="#1">
            <Text1>
              System<br />
              Documentation
            </Text1>
            <Text2>
              システム図書
            </Text2>
            <Num>01</Num>
          </Item>
          <Item to="#2">
            <Text1>
              Parts Book
            </Text1>
            <Text2>
              パーツブック
            </Text2>
            <Num>02</Num>
          </Item>
          <Item to="#3">
            <Text1>
              Production<br />
              Specifications
            </Text1>
            <Text2>
              生産明細書
            </Text2>
            <Num>03</Num>
          </Item>
          <Item to="#4">
            <Text1>
              Data Analysis
            </Text1>
            <Text2>
              整備管理／状態監視
            </Text2>
            <Num>04</Num>
          </Item>
          <Item to="#5">
            <Text1>
              Digitalization
            </Text1>
            <Text2>
              電子化
            </Text2>
            <Num>05</Num>
          </Item>
          <Item to="#6">
            <Text1>
              COSAL Works
            </Text1>
            <Text2>
              コーサル作業
            </Text2>
            <Num>06</Num>
          </Item>
        </Inner3>
      </Inner2>
    </Inner1>
  </Outer>
}