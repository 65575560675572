import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { TopHead } from '../components/top-head'
import { lrem } from '../styles/lrem'
import { pageGrid } from '../styles/page-grid'
import { ContactForm } from '../components/contact-form'
import { Dbr, Mbr } from '../components/br'

const Outer = styled.div`
  padding-top: ${lrem(181)};
  @media (max-width: 40rem){
    padding-top: calc(${lrem(44)} + var(--header-height));
  }
`

const Inner1 = styled.div`
  ${pageGrid}
`

const Head1 = styled.div`
  font-size: ${lrem(50)};
  letter-spacing: 0.05em;
  color: var(--main-color);
  grid-column: 4 / 6;
  font-weight: 700;
  margin-bottom: ${lrem(60)};
  @media (max-width: 40rem){
    font-size: ${lrem(15)};
    margin-bottom: ${lrem(40)};
    margin-top: ${lrem(15)};
    grid-column: 4 / 9;
    text-align: center;
  }
`

const Text1 = styled.div`
  grid-column: 4 / 9;
  font-size: ${lrem(25)};
  letter-spacing: 0.05em;
  line-height: 2.25;
  @media (max-width: 40rem){
    font-size: ${lrem(12)};
  }
  & + & {
    margin-top: ${lrem(50)};
    @media (max-width: 40rem){
      margin-top: ${lrem(30)};
    }
  }
`

const ContactFormContainer = styled.div`
  width: ${lrem(800)};
  margin-left: auto;
  margin-right: auto;
  margin-top: ${lrem(200)};
  @media (max-width: 40rem){
    width: 100%;
    padding-left: var(--page-margin);
    padding-right: var(--page-margin);
    box-sizing: border-box;
  }
`

type ContactSections1Props = {

}
export const ContactSections1: FunctionComponent<ContactSections1Props> = () => {
  return <Outer>
    <TopHead>CONTACT</TopHead>
    <Inner1>
      <Head1>
        お問合せ
      </Head1>
      <Text1>
        当社へのお問合せは、こちらのフォームよりお願い致します。<Dbr />
        携帯電話のメールアドレスを設定のお客様は、「メールの受信拒否、ドメイン指定受信、迷惑メール防止機能」などを設定している場合、受信できない場合がありますのでご了承ください。
      </Text1>
      <Text1>
        If you have any inquiries about our company, please fill out necessary information below.<br />
        Customers who have email rejection filters, domain filtering, or spam filters enabled in mobile phone mail settings may not receive this message.
      </Text1>
    </Inner1>
    <ContactFormContainer>
      <ContactForm />
    </ContactFormContainer>
  </Outer>
}