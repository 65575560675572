import React, { FunctionComponent, ReactElement } from 'react'
import styled, { keyframes } from 'styled-components'

const fadeIn = keyframes`
  from {
    filter: blur(1rem);
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const Outer = styled.div`
  span {
    animation: ${fadeIn} 0.1s both;
  }
`



type AnimatedTextProps = {
  children: string | (string | ReactElement)[]
  delay?: number
  speed?: number
}
export const AnimatedText: FunctionComponent<AnimatedTextProps> = ({children, delay, speed}) => {
  let count = 0
  return <Outer>
    {(Array.isArray(children) ? children : [children]).map(child => {
      return typeof child === 'string' ? child.split('').map((char, i) => {
        count++
        return <span key={i} style={{animationDelay: `${(delay ?? 0) + (speed ?? 0) * count}ms`}}>{char}</span>
      }) : child
    })}
  </Outer>
}