import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { TopHead } from '../components/top-head'
import { lrem } from '../styles/lrem'
import { LinkButton } from '../components/button'
import { Link } from 'react-router-dom'

const Outer = styled.div`
  background-color: #7EC1D6;
`

const Inner = styled.div`
  position: relative;
  max-width: var(--page-max-width);
  margin-left: auto;
  margin-right: auto;
  padding-bottom: ${lrem(400)};
  @media (max-width: 40rem){
    padding-bottom: ${lrem(160)};
  }
`

const HeadContainer = styled.div`
  position: absolute;
  top: ${lrem(148)};
  left: 0;
  width: 100%;
  @media (max-width: 40rem){
    position: static;
    padding: ${lrem(30)} 0;
  }
`

const ImageContainer = styled.div`
  width: ${lrem(1587)};
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  @media (max-width: 40rem){
    width: auto;
  }
`

const ImageItem = styled(Link)`
  display: block;
  overflow: hidden;
  img {
    display: block;
    width: 100%;
    height: auto;
    transition: transform 0.4s;
  }
  &:hover img {
    transform: scale(1.1);
  }
`

const ButtonContainer = styled.div`
  max-width: var(--page-max-content);
  padding: 0 var(--page-side-margin1);
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 40rem){
    padding-left: var(--page-margin);
    padding-right: var(--page-margin);
  }
`

const VoiceButton = styled(LinkButton)`
  background-color: white;
  color: #7EC1D6;
  min-width: ${lrem(360)};
  margin-top: ${lrem(126)};
  @media (max-width: 40rem){
    margin-top: ${lrem(30)};
    min-width: 0;
  }
`

type TopVoicesSectionProps = {

}
export const TopVoicesSection: FunctionComponent<TopVoicesSectionProps> = () => {
  return <Outer>
    <Inner>
      <HeadContainer>
        <TopHead withBar position='right' color='white'>VOICE</TopHead>
      </HeadContainer>
      <ImageContainer>
        <ImageItem to="/voices">
          <img src="/images/voice_0001.jpg" width="1058" height="1058" />
        </ImageItem>
        <ImageItem to="/voices">
          <img src="/images/voice_0002.jpg" width="1058" height="1058" />
        </ImageItem>
        <ImageItem to="/voices">
          <img src="/images/voice_0003.jpg" width="1058" height="1058" />
        </ImageItem>
        <ImageItem to="/voices">
          <img src="/images/voice_0004.jpg" width="1058" height="1058" />
        </ImageItem>
        <ImageItem to="/voices">
          <img src="/images/voice_0005.jpg" width="1058" height="1058" />
        </ImageItem>
        <ImageItem to="/voices">
          <img src="/images/voice_0006.jpg" width="1058" height="1058" />
        </ImageItem>
        <ImageItem to="/voices">
          <img src="/images/voice_0007.jpg" width="1058" height="1058" />
        </ImageItem>
        <ImageItem to="/voices">
          <img src="/images/voice_0008.jpg" width="1058" height="1058" />
        </ImageItem>
        <ImageItem to="/voices">
          <img src="/images/voice_0009.jpg" width="1058" height="1058" />
        </ImageItem>
      </ImageContainer>
      <ButtonContainer>
        <VoiceButton to="/voices">VOICE &gt;</VoiceButton>
      </ButtonContainer>
    </Inner>
  </Outer>
}