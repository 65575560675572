import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import SvgPlayIcon from '../generated/svg/play-icon'
import { lrem } from '../styles/lrem'

const Outer = styled.div`
  background-color: var(--text-color);
  position: relative;
  z-index: 1;
`

const TopContainer = styled.div`
  height: ${lrem(444)};
  background-image: url(/images/footer-background.jpg);
  background-size: cover;
  @media (max-width: 40rem){
    height: ${lrem(120)};
  }
`

const CircleLinkContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  display: flex;
  gap: ${lrem(80)};
  max-width: var(--page-max-width);
  justify-content: center;
  transform: translateY(-50%);
  @media (max-width: 40rem){
    gap: ${lrem(22)}
  }
`

const CircleLinkItem = styled(Link)`
  display: block;
  width: ${lrem(300)};
  aspect-ratio: 1 / 1;
  background-color: #fff;
  border: 1px solid var(--text-color);
  border-radius: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: transform 0.4s;
  &:hover {
    transform: scale(1.05);
  }
  @media (max-width: 40rem){
    width: ${lrem(106)};
  }
`

const CircleLinkText1 = styled.div`
  font-size: ${lrem(30)};
  letter-spacing: 0.025em;
  font-weight: 700;
  @media (max-width: 40rem){
    font-size: ${lrem(13)};
  }
`
const CircleLinkText2 = styled.div`
  font-size: ${lrem(20)};
  font-weight: 700;
  text-align: center;
  line-height: 1.75;
  margin-top: ${lrem(30)};
  @media (max-width: 40rem){
    font-size: ${lrem(9)};
    margin-top: ${lrem(8)};
  }
`

const CircleLinkIconContainer = styled.div`
  height: 0;
  margin-top: ${lrem(20)};
  margin-bottom: ${lrem(-20)};
  @media (max-width: 40rem){
    margin-top: ${lrem(5.5)};
    margin-bottom: 0;
  }
  svg {
    display: block;
    height: ${lrem(30)};
    width: auto;
    @media (max-width: 40rem){
      height: ${lrem(10)};
    }
  }
`

const BottomContainer = styled.div`
  color: white;
  max-width: var(--page-max-content-width);
  box-sizing: border-box;
  padding: ${lrem(66)} var(--page-side-margin1);
  display: flex;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 40rem){
    flex-direction: column;
  }
`

const LeftContainer = styled.div``

const NavigatorContainer = styled.div`
  display: flex;
  gap: ${lrem(60)};
  @media (max-width: 40rem){
    flex-direction: column;
    gap: ${lrem(40)};
  }
`

const NavigatorItem = styled.div``

const NavigatorItemLabel = styled(Link)`
  font-size: ${lrem(20)};
  letter-spacing: 0.1em;
  font-weight: 700;
  display: block;
  @media (max-width: 40rem){
    font-size: ${lrem(15)};
  }
`

const NavigatorListContainer = styled.div`
  display: flex;
  gap: ${lrem(40)};
`

const NavigatorList = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${lrem(22)};
  margin-top: ${lrem(30)};
  @media (max-width: 40rem){
    margin-top: ${lrem(20)};
  }
`

const NavigatorListItem = styled(Link)`
  display: block;
  font-size: ${lrem(16)};
  font-weight: 300;
  @media (max-width: 40rem){
    font-size: ${lrem(13)};
  }
  &::before {
    content: '- ';
  }
`

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const RightList = styled.div`
  display: flex;
  gap: ${lrem(85)};
  @media (max-width: 40rem){
    flex-direction: column;
    gap: ${lrem(40)};
    margin-top: ${lrem(50)};
  }
`

const RightListItem = styled(Link)`
  display: block;
  font-size: ${lrem(20)};
  letter-spacing: 0.1em;
  font-weight: 700;
  @media (max-width: 40rem){
    font-size: ${lrem(15)};
  }
`

const Copyright = styled.div`
  text-align: right;
  font-size: ${lrem(20)};
  letter-spacing: 0.025em;
  @media (max-width: 40rem){
    font-size: ${lrem(15)};
    font-weight: 300;
    margin-top: ${lrem(60)};
    text-align: left;
  }
`

type FooterProps = {

}
export const Footer: FunctionComponent<FooterProps> = () => {
  return <Outer>
    <TopContainer>
      <CircleLinkContainer>
        <CircleLinkItem to="/recruit">
          <CircleLinkText1>RECRUIT</CircleLinkText1>
          <CircleLinkText2>
            採用については<br />
            コチラから
          </CircleLinkText2>
          <CircleLinkIconContainer>
            <SvgPlayIcon />
          </CircleLinkIconContainer>
        </CircleLinkItem>
        <CircleLinkItem to="/contact">
          <CircleLinkText1>CONTACT</CircleLinkText1>
          <CircleLinkText2>
            お問合せは<br />
            コチラから
          </CircleLinkText2>
          <CircleLinkIconContainer>
            <SvgPlayIcon />
          </CircleLinkIconContainer>
        </CircleLinkItem>
      </CircleLinkContainer>
    </TopContainer>
    <BottomContainer>
      <LeftContainer>
        <NavigatorContainer>
          <NavigatorItem>
            <NavigatorItemLabel to="/about">私たちについて</NavigatorItemLabel>
            <NavigatorListContainer>
              <NavigatorList>
                <NavigatorListItem to="/about#1">マリンソフトの使命</NavigatorListItem>
                <NavigatorListItem to="/about#2">代表挨拶</NavigatorListItem>
                <NavigatorListItem to="/about#3">会社概要</NavigatorListItem>
              </NavigatorList>
            </NavigatorListContainer>
          </NavigatorItem>
          <NavigatorItem>
            <NavigatorItemLabel to="/features">マリンソフトの特長</NavigatorItemLabel>
            <NavigatorListContainer>
              <NavigatorList>
                <NavigatorListItem to="/features#1">3つの［S］</NavigatorListItem>
                <NavigatorListItem to="/features#2">企業理念</NavigatorListItem>
                <NavigatorListItem to="/features#3">行動指針</NavigatorListItem>
              </NavigatorList>
            </NavigatorListContainer>
          </NavigatorItem>
          <NavigatorItem>
            <NavigatorItemLabel to="/products">製品・サービス</NavigatorItemLabel>
            <NavigatorListContainer>
              <NavigatorList>
                <NavigatorListItem to="/products#1">システム図書</NavigatorListItem>
                <NavigatorListItem to="/products#2">パーツブック</NavigatorListItem>
                <NavigatorListItem to="/products#3">生産明細書</NavigatorListItem>
              </NavigatorList>
              <NavigatorList>
                <NavigatorListItem to="/products#4">整備管理／状態監視</NavigatorListItem>
                <NavigatorListItem to="/products#5">電子化</NavigatorListItem>
                <NavigatorListItem to="/products#6">コーサル作業</NavigatorListItem>
              </NavigatorList>
            </NavigatorListContainer>
          </NavigatorItem>
        </NavigatorContainer>
      </LeftContainer>
      <RightContainer>
        <RightList>
          <RightListItem to="/voices">スタッフの声</RightListItem>
          <RightListItem to="/recruit">採用情報</RightListItem>
          <RightListItem to="/contact">お問合せ</RightListItem>
        </RightList>
        <Copyright>copyright(c)MARINE SOFT CO.,LTD.</Copyright>
      </RightContainer>
    </BottomContainer>
  </Outer>
}