import * as React from "react";
import type { SVGProps } from "react";
const SvgPlayIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={30}
    height={30}
    data-name="\u30EC\u30A4\u30E4\u30FC 1"
    viewBox="0 0 30 30"
    {...props}
  >
    <path
      fill="currentcolor"
      d="M15 0c8.284 0 15 6.716 15 15s-6.716 15-15 15S0 23.284 0 15 6.716 0 15 0Z"
      data-name="Path 4167"
    />
    <path
      fill="#fff"
      d="m21.187 14.947-10.642 6.344V8.603l10.642 6.344Z"
      data-name="Polygon 3"
    />
  </svg>
);
export default SvgPlayIcon;
