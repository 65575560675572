import * as React from "react";
import type { SVGProps } from "react";
const SvgLogoMobile = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={217.5}
    height={27}
    viewBox="0 0 217.5 27"
    {...props}
  >
    <defs>
      <clipPath id="a">
        <path fill="none" d="M0-.322h27v27H0z" data-name="Rectangle 1865" />
      </clipPath>
    </defs>
    <g data-name="Group 1202">
      <text
        fill="rgba(46,42,42,0.8)"
        data-name="MARINE SOFT Co., Ltd."
        fontFamily="Avenir-Heavy, Avenir"
        fontSize={15}
        fontWeight={800}
        transform="translate(33.5 4)"
      >
        <tspan x={0} y={15}>
          {"MARINE SOFT Co., Ltd."}
        </tspan>
      </text>
      <g data-name="Group 1337">
        <g
          clipPath="url(#a)"
          data-name="Group 1330"
          transform="translate(0 .322)"
        >
          <path
            fill="#0064b6"
            d="M13.551.424a13.127 13.127 0 1 0 13.127 13.128A13.127 13.127 0 0 0 13.551.424m0 22.82a9.692 9.692 0 0 1-.327-19.377l.132-.674a.2.2 0 0 1 .4 0l.132.674a9.692 9.692 0 0 1-.33 19.379"
            data-name="Path 4271"
          />
          <path
            fill="#003288"
            d="M17.118 18.029a22.557 22.557 0 0 1 .927-3.4 24.972 24.972 0 0 1-4.494-2.6v5.628a8.93 8.93 0 0 0 3.567.372"
            data-name="Path 4272"
          />
          <path
            fill="#0092e5"
            d="M10.278 12.94a21.35 21.35 0 0 0 3.123-2.017l.15-.119V9.497l-2.421.776Z"
            data-name="Path 4273"
          />
          <path
            fill="#fff"
            d="M3.178 9.645a.365.365 0 0 1 .139.082.336.336 0 0 1 .085.131.436.436 0 0 1 .025.164.649.649 0 0 1-.035.185.758.758 0 0 1-.119.232.545.545 0 0 1-.188.149l-.082-.165a.405.405 0 0 0 .1-.068.377.377 0 0 0 .073-.092.646.646 0 0 0 .05-.115.324.324 0 0 0 .012-.2.192.192 0 0 0-.259-.107.153.153 0 0 0-.05.04.4.4 0 0 0-.046.077l-.068.138a.441.441 0 0 1-.176.2.321.321 0 0 1-.264.012.374.374 0 0 1-.172-.112.349.349 0 0 1-.08-.184.52.52 0 0 1 .027-.233.651.651 0 0 1 .1-.2.523.523 0 0 1 .162-.132l.079.159a.323.323 0 0 0-.083.06.368.368 0 0 0-.058.078.555.555 0 0 0-.042.1.29.29 0 0 0-.015.129.188.188 0 0 0 .042.1.2.2 0 0 0 .09.059.152.152 0 0 0 .13-.008.238.238 0 0 0 .055-.048.33.33 0 0 0 .047-.071l.068-.133a.66.66 0 0 1 .08-.126.3.3 0 0 1 .087-.073.333.333 0 0 1 .285-.017"
            data-name="Path 4274"
          />
          <path
            fill="#fff"
            d="M3.357 8.533q.14.062.212.1a.543.543 0 0 1 .127.091.387.387 0 0 1 .087.132.467.467 0 0 1-.012.368.462.462 0 0 1-.263.255.388.388 0 0 1-.156.026.543.543 0 0 1-.153-.032q-.078-.028-.218-.09-.108-.048-.17-.079a.876.876 0 0 1-.11-.064.408.408 0 0 1-.084-.077.4.4 0 0 1-.06-.106.462.462 0 0 1 .012-.367.467.467 0 0 1 .264-.257.386.386 0 0 1 .156-.025.547.547 0 0 1 .152.032q.077.028.217.09m-.079.18a1.714 1.714 0 0 0-.254-.1.253.253 0 0 0-.143 0 .243.243 0 0 0-.088.055.275.275 0 0 0-.063.089.281.281 0 0 0-.024.107.245.245 0 0 0 .018.1.262.262 0 0 0 .094.109 1.69 1.69 0 0 0 .242.123c.063.028.113.049.151.064a.79.79 0 0 0 .1.033.293.293 0 0 0 .074.01.274.274 0 0 0 .069-.013.256.256 0 0 0 .088-.055.273.273 0 0 0 .063-.089.253.253 0 0 0 .005-.208.235.235 0 0 0-.052-.076.456.456 0 0 0-.1-.069q-.067-.036-.182-.087"
            data-name="Path 4275"
          />
          <path
            fill="#fff"
            d="m3.46 7.375-.308.55.344.193.263-.469.154.086-.263.469.477.267-.1.173-1.129-.633.4-.722Z"
            data-name="Path 4276"
          />
          <path
            fill="#fff"
            d="m4.059 6.49-.2.293.921.633-.111.162-.921-.633-.2.294-.146-.1.515-.749Z"
            data-name="Path 4277"
          />
          <path
            fill="#fff"
            d="m5.017 5.085.752 1.106-.112.129-.857-.4.511.794-.113.129-1.2-.6.136-.156.845.438-.526-.8.1-.119.868.412-.548-.778Z"
            data-name="Path 4278"
          />
          <path
            fill="#fff"
            d="m6.814 5.216-.156.14-.24-.134-.38.341.108.253-.156.14-.512-1.28.117-.106Zm-.548-.082-.529-.3.234.562Z"
            data-name="Path 4279"
          />
          <path
            fill="#fff"
            d="m7.658 4.6-.185.135-.541-.278-.2.145.321.439-.16.117-.764-1.045.406-.3a.447.447 0 0 1 .2-.085.343.343 0 0 1 .193.025.386.386 0 0 1 .155.129.345.345 0 0 1 .074.222.353.353 0 0 1-.079.208Zm-.73-.6a.191.191 0 0 0-.137-.085.229.229 0 0 0-.161.051l-.234.171.239.327.234-.171a.231.231 0 0 0 .1-.138.188.188 0 0 0-.038-.154"
            data-name="Path 4280"
          />
          <path
            fill="#fff"
            d="m8.494 4.106-.713.422-.659-1.114.713-.422.09.152-.542.321.192.325.463-.274.089.151-.463.274.2.335.542-.321Z"
            data-name="Path 4281"
          />
          <path
            fill="#fff"
            d="m9.978 3.447-.768.309-.482-1.2.768-.309.066.164-.585.235.141.35.5-.2.065.162-.5.2.145.361.585-.235Z"
            data-name="Path 4282"
          />
          <path
            fill="#fff"
            d="m11.061 3.127-.173.05-.825-.71.251.875-.19.055-.357-1.244.174-.05.826.709-.251-.874.189-.054Z"
            data-name="Path 4283"
          />
          <path
            fill="#fff"
            d="M12.066 2.434a.73.73 0 0 1 .011.213.385.385 0 0 1-.062.166.468.468 0 0 1-.325.206.5.5 0 0 1-.128.007.455.455 0 0 1-.121-.027.472.472 0 0 1-.109-.056.392.392 0 0 1-.109-.115.544.544 0 0 1-.059-.144q-.02-.08-.047-.231-.02-.116-.029-.185a.866.866 0 0 1-.008-.128.408.408 0 0 1 .017-.112.39.39 0 0 1 .054-.109.461.461 0 0 1 .311-.194.49.49 0 0 1 .238.012.418.418 0 0 1 .182.114.541.541 0 0 1 .113.189l-.195.034a.329.329 0 0 0-.072-.11.244.244 0 0 0-.1-.061.292.292 0 0 0-.131 0 .274.274 0 0 0-.1.039.244.244 0 0 0-.074.072.268.268 0 0 0-.039.139 1.574 1.574 0 0 0 .031.27q.018.1.031.159a.823.823 0 0 0 .03.1.286.286 0 0 0 .036.071.323.323 0 0 0 .05.051.242.242 0 0 0 .093.042.275.275 0 0 0 .109 0 .265.265 0 0 0 .191-.126.3.3 0 0 0 .03-.211l-.013-.072-.272.048-.029-.164.465-.082Z"
            data-name="Path 4284"
          />
          <path
            fill="#fff"
            d="m12.199 1.629.197-.02.135 1.287-.197.02z"
            data-name="Rectangle 1860"
          />
          <path
            fill="#fff"
            d="m13.714 2.838-.18.005-.624-.893.027.91-.2.006-.038-1.293.181-.005.625.891-.027-.908.2-.006Z"
            data-name="Path 4285"
          />
          <path
            fill="#fff"
            d="m14.745 2.904-.826-.057.094-1.29.821.055-.012.176-.629-.043-.026.376.537.037-.012.175-.537-.037-.027.388.629.043Z"
            data-name="Path 4286"
          />
          <path
            fill="#fff"
            d="m15.723 3.057-.817-.133.207-1.277.817.133-.028.174-.622-.1-.06.372.531.086-.028.173-.531-.086-.062.384.622.1Z"
            data-name="Path 4287"
          />
          <path
            fill="#fff"
            d="m16.79 3.333-.221-.059-.125-.6-.237-.063-.139.526-.191-.051.33-1.251.486.128a.447.447 0 0 1 .192.1.345.345 0 0 1 .1.166.385.385 0 0 1 0 .2.344.344 0 0 1-.126.2.353.353 0 0 1-.211.07Zm0-.944a.192.192 0 0 0-.02-.16.228.228 0 0 0-.141-.093l-.281-.074-.1.391.28.074a.23.23 0 0 0 .168-.012.187.187 0 0 0 .1-.127"
            data-name="Path 4288"
          />
          <path
            fill="#fff"
            d="m16.929 3.391.42-1.224.187.064-.42 1.224z"
            data-name="Rectangle 1861"
          />
          <path
            fill="#fff"
            d="m18.218 3.903-.166-.071-.19-1.072-.359.837-.182-.078.51-1.189.166.071.191 1.071.358-.835.18.077Z"
            data-name="Path 4289"
          />
          <path
            fill="#fff"
            d="M19.432 3.997a.727.727 0 0 1-.126.172.382.382 0 0 1-.153.089.468.468 0 0 1-.382-.045.509.509 0 0 1-.1-.076.456.456 0 0 1-.077-.1.472.472 0 0 1-.049-.113.388.388 0 0 1-.011-.158.54.54 0 0 1 .046-.149q.035-.075.11-.209l.094-.162a.89.89 0 0 1 .074-.1.414.414 0 0 1 .084-.076.4.4 0 0 1 .111-.05.461.461 0 0 1 .364.046.494.494 0 0 1 .177.159.417.417 0 0 1 .069.2.539.539 0 0 1-.031.218l-.173-.1a.328.328 0 0 0 .013-.131.244.244 0 0 0-.042-.113.291.291 0 0 0-.1-.086.271.271 0 0 0-.1-.033.24.24 0 0 0-.1.009.268.268 0 0 0-.118.083 1.6 1.6 0 0 0-.146.229q-.05.09-.076.143a.824.824 0 0 0-.042.1.288.288 0 0 0-.017.077.324.324 0 0 0 .006.071.244.244 0 0 0 .046.091.277.277 0 0 0 .083.071.264.264 0 0 0 .228.023.3.3 0 0 0 .156-.145l.035-.063-.241-.135.082-.146.412.231Z"
            data-name="Path 4290"
          />
          <path
            fill="#fff"
            d="M20.748 5.124a.365.365 0 0 1-.124.1.339.339 0 0 1-.152.035.438.438 0 0 1-.163-.032.644.644 0 0 1-.162-.1.757.757 0 0 1-.178-.191.542.542 0 0 1-.076-.228l.183-.021a.409.409 0 0 0 .03.116.376.376 0 0 0 .062.1.638.638 0 0 0 .091.086.324.324 0 0 0 .186.08.192.192 0 0 0 .189-.208.154.154 0 0 0-.021-.061.411.411 0 0 0-.056-.07l-.107-.111a.441.441 0 0 1-.129-.234.321.321 0 0 1 .079-.252.375.375 0 0 1 .164-.124.35.35 0 0 1 .2-.012.52.52 0 0 1 .21.105.649.649 0 0 1 .155.167.52.52 0 0 1 .07.2l-.176.02a.325.325 0 0 0-.028-.1.365.365 0 0 0-.053-.082.552.552 0 0 0-.077-.073.289.289 0 0 0-.115-.058.189.189 0 0 0-.108.006.2.2 0 0 0-.086.065.154.154 0 0 0-.036.125.238.238 0 0 0 .027.068.34.34 0 0 0 .051.069l.1.109a.659.659 0 0 1 .092.118.309.309 0 0 1 .039.106.334.334 0 0 1-.081.274"
            data-name="Path 4291"
          />
          <path
            fill="#fff"
            d="M21.596 5.832a.45.45 0 0 1-.205.127.415.415 0 0 1-.23 0 .534.534 0 0 1-.343-.329.413.413 0 0 1-.01-.229.451.451 0 0 1 .118-.21l.593-.619.141.136-.587.612a.318.318 0 0 0-.079.13.232.232 0 0 0 0 .135.309.309 0 0 0 .2.2.234.234 0 0 0 .135 0 .314.314 0 0 0 .127-.084l.587-.612.141.136Z"
            data-name="Path 4292"
          />
          <path
            fill="#fff"
            d="M22.698 6.39a.4.4 0 0 1-.186.093.357.357 0 0 1-.2-.019.454.454 0 0 1-.181-.133l-.191-.223-.386.331-.129-.15.983-.841.319.373a.481.481 0 0 1 .088.149.381.381 0 0 1 .024.155.357.357 0 0 1-.04.143.418.418 0 0 1-.1.123m-.128-.149a.2.2 0 0 0 .08-.151.236.236 0 0 0-.065-.164l-.184-.215-.327.28.184.215a.237.237 0 0 0 .152.09.2.2 0 0 0 .16-.055"
            data-name="Path 4293"
          />
          <path
            fill="#fff"
            d="M23.353 7.315a.4.4 0 0 1-.194.074.358.358 0 0 1-.2-.039.454.454 0 0 1-.167-.15l-.168-.241-.417.292-.114-.162 1.06-.742.282.4a.485.485 0 0 1 .074.157.383.383 0 0 1 .009.157.356.356 0 0 1-.054.138.416.416 0 0 1-.112.113m-.113-.16a.205.205 0 0 0 .094-.142.236.236 0 0 0-.048-.169l-.162-.232-.353.247.162.232a.237.237 0 0 0 .142.1.2.2 0 0 0 .164-.039"
            data-name="Path 4294"
          />
          <path
            fill="#fff"
            d="M23.687 8.415q-.134.075-.207.11a.545.545 0 0 1-.149.046.387.387 0 0 1-.157-.009.467.467 0 0 1-.288-.231.461.461 0 0 1-.046-.364.387.387 0 0 1 .073-.141.54.54 0 0 1 .117-.1q.068-.046.2-.121.1-.058.165-.089a.893.893 0 0 1 .118-.05.405.405 0 0 1 .112-.021.4.4 0 0 1 .121.015.462.462 0 0 1 .286.229.469.469 0 0 1 .047.365.389.389 0 0 1-.074.139.548.548 0 0 1-.117.1q-.069.045-.2.12m-.1-.171a1.726 1.726 0 0 0 .23-.146.252.252 0 0 0 .083-.117.243.243 0 0 0 .009-.1.272.272 0 0 0-.033-.1.277.277 0 0 0-.072-.083.244.244 0 0 0-.093-.046.264.264 0 0 0-.144.01 1.7 1.7 0 0 0-.244.12q-.09.05-.142.083a.774.774 0 0 0-.088.063.307.307 0 0 0-.052.054.279.279 0 0 0-.031.063.251.251 0 0 0-.009.1.275.275 0 0 0 .034.1.253.253 0 0 0 .164.129.236.236 0 0 0 .093 0 .461.461 0 0 0 .116-.039q.069-.032.179-.094"
            data-name="Path 4295"
          />
          <path
            fill="#fff"
            d="m23.575 9.755-.091-.21.39-.467-.1-.225-.5.217-.079-.182 1.186-.517.2.461a.443.443 0 0 1 .04.213.343.343 0 0 1-.066.183.387.387 0 0 1-.16.123.345.345 0 0 1-.233.023.354.354 0 0 1-.186-.122Zm.743-.581a.192.192 0 0 0 .113-.115.228.228 0 0 0-.014-.169l-.116-.266-.371.162.116.266a.23.23 0 0 0 .113.125.187.187 0 0 0 .159 0"
            data-name="Path 4296"
          />
          <path
            fill="#fff"
            d="m24.937 10.329-.11-.338-1.063.345-.061-.187 1.063-.345-.11-.339.168-.055.281.864Z"
            data-name="Path 4297"
          />
          <path
            fill="#fff"
            d="m3.181 20.414-.242-.431.839-.473-.573-.005-.155-.276.293-.493-.84.473-.242-.431 1.754-.987.238.423-.552.829 1-.041.238.423Z"
            data-name="Path 4298"
          />
          <path
            fill="#fff"
            d="m4.641 22.212-.333-.395.169-.265-.4-.474-.292.118-.334-.4 2.011-.737.247.293Zm.068-1.025.3-.476-.527.207Z"
            data-name="Path 4299"
          />
          <path
            fill="#fff"
            d="m6.176 23.542-.435-.37.214-.8-.128-.108-.486.571-.376-.32 1.3-1.533.608.517a.721.721 0 0 1 .224.309.613.613 0 0 1 .025.334.654.654 0 0 1-.145.295.542.542 0 0 1-.283.185.62.62 0 0 1-.308 0Zm.426-1.429a.2.2 0 0 0 .046-.093.193.193 0 0 0-.008-.106.223.223 0 0 0-.069-.1l-.214-.182-.263.309.214.182a.225.225 0 0 0 .105.053.194.194 0 0 0 .105-.008.2.2 0 0 0 .084-.06"
            data-name="Path 4300"
          />
          <path
            fill="#fff"
            d="m6.527 23.758 1.107-1.682.412.272L6.94 24.03z"
            data-name="Rectangle 1862"
          />
          <path
            fill="#fff"
            d="m8.805 25.019-.388-.19-.134-1.192-.445.909-.444-.217.885-1.808.388.19.134 1.192.445-.909.444.217Z"
            data-name="Path 4301"
          />
          <path
            fill="#fff"
            d="m10.671 25.616-1.311-.392.577-1.928 1.311.392-.126.422-.837-.25-.1.323.716.214-.126.422-.715-.214-.1.34.837.25Z"
            data-name="Path 4302"
          />
          <path
            fill="#fff"
            d="M13.287 25.334a.565.565 0 0 1-.132.331.644.644 0 0 1-.3.194 1.009 1.009 0 0 1-.4.042 1.432 1.432 0 0 1-.429-.095.8.8 0 0 1-.311-.232l.342-.291a.437.437 0 0 0 .187.124.936.936 0 0 0 .249.054q.287.024.3-.157a.165.165 0 0 0-.03-.122.222.222 0 0 0-.128-.064l-.239-.054a.674.674 0 0 1-.389-.21.556.556 0 0 1-.1-.415.613.613 0 0 1 .116-.32.6.6 0 0 1 .273-.2.876.876 0 0 1 .4-.049 1.2 1.2 0 0 1 .383.089.746.746 0 0 1 .275.206l-.335.284a.346.346 0 0 0-.159-.112.977.977 0 0 0-.213-.038.26.26 0 0 0-.185.042.173.173 0 0 0-.073.124.129.129 0 0 0 .032.1.234.234 0 0 0 .133.071l.239.053a.7.7 0 0 1 .381.2.439.439 0 0 1 .1.193.835.835 0 0 1 .014.259"
            data-name="Path 4303"
          />
          <path
            fill="#fff"
            d="M15.143 24.84v.067q.012.168.009.29a.87.87 0 0 1-.039.238.59.59 0 0 1-.12.214.774.774 0 0 1-.233.186.8.8 0 0 1-.312.08.9.9 0 0 1-.222-.01.688.688 0 0 1-.187-.062.779.779 0 0 1-.167-.114.6.6 0 0 1-.189-.288 2.109 2.109 0 0 1-.066-.424v-.135a2.118 2.118 0 0 1 .005-.43.6.6 0 0 1 .146-.313.763.763 0 0 1 .235-.187.921.921 0 0 1 .628-.044.765.765 0 0 1 .258.151.586.586 0 0 1 .149.195.871.871 0 0 1 .073.231q.02.12.032.288Zm-.493.035a2.314 2.314 0 0 0-.042-.349.33.33 0 0 0-.066-.139.2.2 0 0 0-.085-.057.275.275 0 0 0-.12-.016.265.265 0 0 0-.112.032.23.23 0 0 0-.078.07.263.263 0 0 0-.035.081.639.639 0 0 0-.014.144c0 .064 0 .155.011.274s.018.208.028.272a.64.64 0 0 0 .034.139.269.269 0 0 0 .046.074.261.261 0 0 0 .395-.028.365.365 0 0 0 .046-.148 2.263 2.263 0 0 0-.008-.347"
            data-name="Path 4304"
          />
          <path
            fill="#fff"
            d="m16.715 23.917-.852.2.08.345.728-.168.1.429-.728.168.175.759-.482.111-.452-1.961 1.333-.307Z"
            data-name="Path 4305"
          />
          <path
            fill="#fff"
            d="m18.359 23.266-.458.188.6 1.454-.457.188-.6-1.454-.459.188-.167-.407 1.374-.565Z"
            data-name="Path 4306"
          />
          <path
            fill="#fff"
            d="M20.581 23.103a.776.776 0 0 1 .066.25.575.575 0 0 1-.025.229.594.594 0 0 1-.108.194.763.763 0 0 1-.176.155.644.644 0 0 1-.507.1.512.512 0 0 1-.261-.138 1.823 1.823 0 0 1-.225-.291l-.031-.049-.032-.049a1.821 1.821 0 0 1-.174-.325.511.511 0 0 1-.02-.3.652.652 0 0 1 .1-.238.694.694 0 0 1 .2-.185.768.768 0 0 1 .217-.1.573.573 0 0 1 .435.052.77.77 0 0 1 .2.163l-.361.234a.294.294 0 0 0-.134-.073.2.2 0 0 0-.152.039.228.228 0 0 0-.071.07.189.189 0 0 0-.03.081.217.217 0 0 0 .007.077.551.551 0 0 0 .048.113q.037.072.123.2a1.878 1.878 0 0 0 .173.238.325.325 0 0 0 .114.084.2.2 0 0 0 .18-.029.2.2 0 0 0 .1-.122.286.286 0 0 0-.011-.153Z"
            data-name="Path 4307"
          />
          <path
            fill="#fff"
            d="M21.55 22.161a1.182 1.182 0 0 1 .171.251.481.481 0 0 1 .042.222.55.55 0 0 1-.214.4.544.544 0 0 1-.428.142.482.482 0 0 1-.145-.04.544.544 0 0 1-.134-.089 1.467 1.467 0 0 1-.152-.16 1.515 1.515 0 0 1-.131-.176.543.543 0 0 1-.065-.147.533.533 0 0 1-.016-.151.558.558 0 0 1 .054-.2.594.594 0 0 1 .159-.2.6.6 0 0 1 .22-.124.531.531 0 0 1 .209-.019.476.476 0 0 1 .212.08 1.163 1.163 0 0 1 .218.211m-.3.256a1.056 1.056 0 0 0-.122-.127.166.166 0 0 0-.1-.043.142.142 0 0 0-.105.041.144.144 0 0 0-.058.1.182.182 0 0 0 .029.107 1.057 1.057 0 0 0 .1.137 1.029 1.029 0 0 0 .117.122.194.194 0 0 0 .1.048.174.174 0 0 0 .163-.137.18.18 0 0 0-.027-.1 1.014 1.014 0 0 0-.1-.141"
            data-name="Path 4308"
          />
          <path
            fill="#fff"
            d="m21.849 22.159.31-.308.308.31-.31.308z"
            data-name="Rectangle 1863"
          />
          <path
            fill="#fff"
            d="m23.037 21.718-.049.521-.636-.569.281-.314Z"
            data-name="Path 4309"
          />
          <path
            fill="#fff"
            d="m23.855 20.475-.69.917-1.378-1.038.255-.338 1.077.811.435-.579Z"
            data-name="Path 4310"
          />
          <path
            fill="#fff"
            d="m24.331 19.692-.1.17a.428.428 0 0 1-.167.165.35.35 0 0 1-.195.039.416.416 0 0 1-.179-.059l-.479-.294-.062.1-.26-.159.062-.1-.318-.195.208-.339.318.195.108-.177.26.159-.108.177.455.279a.071.071 0 0 0 .11-.027l.066-.108Z"
            data-name="Path 4311"
          />
          <path
            fill="#fff"
            d="m24.959 18.459-.169.346-.1-.047a.442.442 0 0 1 .024.16.423.423 0 0 1-.047.163.435.435 0 0 1-.246.234.363.363 0 0 1-.218 0 1.636 1.636 0 0 1-.273-.108l-.054-.026-.054-.026a1.631 1.631 0 0 1-.253-.149.349.349 0 0 1-.151-.281.461.461 0 0 1 .01-.115.522.522 0 0 1 .04-.114.392.392 0 0 1 .225-.213l-.464-.226.174-.357Zm-.76.072a1.056 1.056 0 0 0-.157-.064.188.188 0 0 0-.112 0 .146.146 0 0 0-.079.081.129.129 0 0 0 .008.152.644.644 0 0 0 .2.131 1.049 1.049 0 0 0 .158.064.186.186 0 0 0 .112 0 .179.179 0 0 0 .094-.193.185.185 0 0 0-.072-.086 1.055 1.055 0 0 0-.148-.085"
            data-name="Path 4312"
          />
          <path
            fill="#fff"
            d="m24.666 18.004.156-.408.409.156-.157.408z"
            data-name="Rectangle 1864"
          />
          <path
            fill="#003288"
            d="M22.053 17.541c-1.095 1.355-2.588 2.064-5.213 1.933-3.239-.162-5.63-1.946-8.976-2.816a6.706 6.706 0 0 0-3.2-.063 9.391 9.391 0 0 0 17.385.946"
            data-name="Path 4313"
          />
          <path
            fill="#fff"
            d="M5.125 16.891a7.054 7.054 0 0 1 1.805.321c3.3 1.042 5.592 2.947 8.819 3.276a6.942 6.942 0 0 0 3.7-.443 8.571 8.571 0 0 1-2.527.126c-3.233-.254-5.573-2.106-8.892-3.071a6.8 6.8 0 0 0-2.9-.209"
            data-name="Path 4314"
          />
          <path
            fill="#0092e5"
            d="M22.687 11.381a4.644 4.644 0 0 0-2.06-1.815 4.268 4.268 0 0 0-2.7-.317 4.409 4.409 0 0 1 3.2 5.056 4.516 4.516 0 0 1-2.982 3.362 8.121 8.121 0 0 1-4.98-.248 32.007 32.007 0 0 0-4.859-1.425 6.037 6.037 0 0 0-3.676.48l.04.118a6.706 6.706 0 0 1 3.2.063c3.346.87 5.737 2.654 8.976 2.816 2.625.131 4.118-.578 5.213-1.933a9.385 9.385 0 0 0 .634-6.159"
            data-name="Path 4315"
          />
          <path
            fill="#003288"
            d="M8.499 16.028q.27.044.536.1a18.442 18.442 0 0 0-.6-1.766 24.717 24.717 0 0 0 5.112-2.943 24.721 24.721 0 0 0 5.112 2.943 20.964 20.964 0 0 0-1.008 3.445c.164-.039.326-.083.487-.139l.045-.017a19.789 19.789 0 0 1 1.015-3.328l.1-.229-.231-.09c-.011 0-.41-.162-1.029-.455l-1.295-4.05-2.094-.664-.388-1.98h1.2L16 6.37h-1.834l-.614-3.135-.614 3.135h-1.835l.539.485h1.2l-.388 1.98-2.095.671-1.295 4.049c-.62.292-1.019.45-1.029.455l-.231.09.1.229a17.014 17.014 0 0 1 .6 1.7m5.053-10.276.57 2.911-.57-.183-.57.183Zm-2.806 4.136 2.806-.9 2.806.9 1.078 3.37a22.286 22.286 0 0 1-3.733-2.335l-.15-.119-.15.119a22.28 22.28 0 0 1-3.733 2.334Z"
            data-name="Path 4316"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default SvgLogoMobile;
