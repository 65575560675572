import React, { FunctionComponent } from 'react'
import styled, { keyframes } from 'styled-components'
import { lrem } from '../styles/lrem'

const Outer = styled.div`
  max-width: 100vw;
  overflow: hidden;
  padding-top: ${lrem(283)};
  @media (max-width: 40rem){
    padding-top: ${lrem(40)};
  }
`

const scroll = keyframes`
  from {
    transform: translateX(-25%);
  }
  to {
    transform: translateX(-75%);
  }
`

const Scroll = styled.div`
  display: flex;
  justify-content: center;
  animation: ${scroll} 60s linear infinite normal;
  width: min-content;
  & + & {
    margin-top: ${lrem(130)};
    @media (max-width: 40rem){
      margin-top: ${lrem(14)};
    }
  }
  img {
    display: block;
    width: auto;
    height: ${lrem(500)};
    margin-right: ${lrem(40)};
    @media (max-width: 40rem){
      height: ${lrem(164)};
      margin-right: ${lrem(12)};
    }
  }
`

const ScrollReverse = styled(Scroll)`
  animation-direction: reverse;
`

type VoicesSection2Props = {

}
export const VoicesSection2: FunctionComponent<VoicesSection2Props> = () => {
  return <Outer>

    <Scroll>
      <img src="/images/voice_s_0001.jpg" width="1200" height="1000" />
      <img src="/images/voice_s_0002.jpg" width="1200" height="1000" />
      <img src="/images/voice_s_0003.jpg" width="1200" height="1000" />
      <img src="/images/voice_s_0004.jpg" width="1200" height="1000" />
      <img src="/images/voice_s_0005.jpg" width="1200" height="1000" />
      <img src="/images/voice_s_0006.jpg" width="1200" height="1000" />
      <img src="/images/voice_s_0007.jpg" width="1200" height="1000" />
      <img src="/images/voice_s_0008.jpg" width="1200" height="1000" />
      <img src="/images/voice_s_0009.jpg" width="1200" height="1000" />
      <img src="/images/voice_s_0010.jpg" width="1200" height="1000" />
      <img src="/images/voice_s_0011.jpg" width="1200" height="1000" />
      <img src="/images/voice_s_0012.jpg" width="1200" height="1000" />
      <img src="/images/voice_s_0013.jpg" width="1200" height="1000" />
      <img src="/images/voice_s_0014.jpg" width="1200" height="1000" />
      <img src="/images/voice_s_0015.jpg" width="1200" height="1000" />
      <img src="/images/voice_s_0016.jpg" width="1200" height="1000" />
      <img src="/images/voice_s_0017.jpg" width="1200" height="1000" />
      <img src="/images/voice_s_0018.jpg" width="1200" height="1000" />
      <img src="/images/voice_s_0019.jpg" width="1200" height="1000" />
      <img src="/images/voice_s_0001.jpg" width="1200" height="1000" />
      <img src="/images/voice_s_0002.jpg" width="1200" height="1000" />
      <img src="/images/voice_s_0003.jpg" width="1200" height="1000" />
      <img src="/images/voice_s_0004.jpg" width="1200" height="1000" />
      <img src="/images/voice_s_0005.jpg" width="1200" height="1000" />
      <img src="/images/voice_s_0006.jpg" width="1200" height="1000" />
      <img src="/images/voice_s_0007.jpg" width="1200" height="1000" />
      <img src="/images/voice_s_0008.jpg" width="1200" height="1000" />
      <img src="/images/voice_s_0009.jpg" width="1200" height="1000" />
      <img src="/images/voice_s_0010.jpg" width="1200" height="1000" />
      <img src="/images/voice_s_0011.jpg" width="1200" height="1000" />
      <img src="/images/voice_s_0012.jpg" width="1200" height="1000" />
      <img src="/images/voice_s_0013.jpg" width="1200" height="1000" />
      <img src="/images/voice_s_0014.jpg" width="1200" height="1000" />
      <img src="/images/voice_s_0015.jpg" width="1200" height="1000" />
      <img src="/images/voice_s_0016.jpg" width="1200" height="1000" />
      <img src="/images/voice_s_0017.jpg" width="1200" height="1000" />
      <img src="/images/voice_s_0018.jpg" width="1200" height="1000" />
      <img src="/images/voice_s_0019.jpg" width="1200" height="1000" />
    </Scroll>
  </Outer>
}