import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { RecruitSection1 } from '../sections/recruit-section1'

const Outer = styled.div``

type RecruitPageProps = {

}
export const RecruitPage: FunctionComponent<RecruitPageProps> = () => {
  return <Outer>
    <RecruitSection1 />
  </Outer>
}