import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { lrem } from '../styles/lrem'
import { pageGrid } from '../styles/page-grid'

const Outer = styled.div`
  display: grid;
  grid-template-columns:
    minmax(var(--page-margin), 1fr)
    calc(var(--page-side-margin1) + var(--page-side-margin2))
    calc((var(--page-side-content-width) * 2 + var(--page-center-margin) * 2 + var(--page-side-margin2)) / 2)
    calc((var(--page-side-content-width) * 2 + var(--page-center-margin) * 2 + var(--page-side-margin2)) / 2)
    calc(var(--page-side-margin1))
    minmax(var(--page-margin), 1fr)
  ;
  margin-top: ${lrem(283)};
  @media (max-width: 40rem){
    display: flex;
    flex-direction: column;
    margin-top: ${lrem(80)};
  }
`

const Section = styled.div`
  display: flex;
  padding-top: ${lrem(233)};
  padding-bottom: ${lrem(233)};
  @media (max-width: 40rem){
    padding: ${lrem(40)} var(--page-margin) !important;
    background-color: transparent !important;
    &:nth-child(odd) {
      background-color: #EDF8FC !important;
    }
  }
  &:nth-child(odd) {
    grid-column: 1 / 4;
    justify-content: flex-end;
    padding-right: ${lrem(125)};
  }
  &:nth-child(even) {
    grid-column: 4 / 7;
    padding-left: ${lrem(125)};
  }
  &:nth-child(4n),
  &:nth-child(4n + 1) {
    background-color: #EDF8FC;
  }
`

const Inner = styled.div`
  width: ${lrem(565)};
`

const Head = styled.div`
  font-size: ${lrem(32)};
  letter-spacing: 0.025em;
  line-height: 1.562;
  color: var(--main-color);
  padding-bottom: ${lrem(16)};
  margin-bottom: ${lrem(50)};
  border-bottom: 1px solid currentColor;
  @media (max-width: 40rem){
    font-size: ${lrem(20)};
  }
`

const Text1 = styled.div`
  font-size: ${lrem(30)};
  line-height: 1.562;
  @media (max-width: 40rem){
    font-size: ${lrem(16)};
    letter-spacing: -0.02em;
  }
`

const Text2 = styled.div`
  margin-top: ${lrem(30)};
  font-size: ${lrem(18)};
  letter-spacing: 0.02em;
  line-height: 2.222222222;
  @media (max-width: 40rem){
    font-size: ${lrem(13)};
  }
`

type VoicesSection3Props = {

}
export const VoicesSection3: FunctionComponent<VoicesSection3Props> = () => {
  return <Outer>
    <Section id="1">
      <Inner>
        <Head>
          システム図書グループ<br />
          入社9年目
        </Head>
        <Text1>
          よりわかりやすい表現方法を追求し、<br />
          お客様の理解向上に貢献したい
        </Text1>
        <Text2>
          新たに艦船の乗組員となった方々に向けて、搭載されている機器・装置の構造や作動、使い方を紹介する3Dアニメーションを作成しています。取扱説明書にある文章だけでは把握しにくい装置であっても、CGを使った立体モデルを作成し動きをつけることでよりスムーズに理解していただくことを目的としています。お客様からご提供いただいた複数の平面図からイメージをつかみ、立体化することは容易ではありませんが、時間をかけた分だけ正確でわかりやすいものに仕上げられた時は大きな達成感を得ることができます。また、業務に臨むうえで常に、第三者の客観的な視点で確認してもらうことを大切にしています。社内には専門分野に長けた方がいるので、何度も立ち止まって勘違いしている点がないかを問い直すようにしています。業務の立ち上げから参加して9年が経ちますが、これからもお客様に貢献できることは何かを日々考え、よりわかりやすい視覚表現を追求していきたいと思っています。
        </Text2>
      </Inner>
    </Section>
    <Section id="2">
      <Inner>
        <Head>
          電子化グループ<br />
          入社13年目
        </Head>
        <Text1>
          技術革新をいち早く取り入れ、<br />
          利便性の高いデジタル図書を追求する
        </Text1>
        <Text2>
          海上自衛隊の艦船には、目的別に多種多様な取扱説明書が必要とされます。1冊につき数ページ程度のものから1000ページ以上のものまでボリュームはさまざまですが、私たちの電子化グループでは、それら膨大な紙の資料をデジタルデータに変換。高度なインデックス機能や検索機能付きのデジタル文書にすることで、業務の迅速化に貢献しています。処理する量は膨大ですが、その分だけすべて仕上げた後の充実感は特別なものがあります。日々の業務に取り組むうえで大切にしているのは、任された仕事に対して常に誠実に向き合うこと。小さな問題であってもその都度、確認や報告を怠ることなく、お受けした仕事を確実にやり遂げることが、お客様の期待に応えることだと思っています。これからも技術革新にアンテナを張り、先の未来を見据えた準備を進めていくつもりです。
        </Text2>
      </Inner>
    </Section>
    <Section id="3">
      <Inner>
        <Head>
          システム図書グループ<br />
          入社5年目
        </Head>
        <Text1>
          わかりやすさを突き詰めることで、<br />
          ユーザの理解をサポートしたい
        </Text1>
        <Text2>
          システム図書の内容を補足的に説明するためのイラストやアニメーションを作成しています。私の業務では、閲覧する方の視点に立って考えることがとても重要です。その文書を読んだうえで、何がわかりにくいのか、どのようなイラストをどの角度で描くかなど、見る方の疑問を解消することを考えてビジュアルを作成する必要があります。基本的にはいくつもの図面を照らし合わせながらイメージをつくりますが、個人的に写真や動画から調べたり、実際の艦船を見学に行くことも。装置それぞれに用途があり、設置する場所や構造などにしっかりとした理由があるので、それらを頭の中で整理しながら組み立てていく作業に面白さを感じています。マリンソフトに入社して5年。仕事の特性上、業務を他の方に手伝っていただく機会はあまりありませんが、経験豊富なベテランの方であっても気遣いのできる方ばかりなので、良好な人間関係の中、余裕を持って業務に取り組むことができています。
        </Text2>
      </Inner>
    </Section>
    <Section id="4">
      <Inner>
        <Head>
          長崎エンジニアリング部<br />
          入社29年目
        </Head>
        <Text1>
          キャリアを通して得た知識を活かし、<br />
          正確で質の高い仕事を継続する
        </Text1>
        <Text2>
          新しい艦船を建造する際に提出する生産明細書を作成しています。これは、艦船を構成する材料や部品・装備品などを一覧にしてまとめたもので、間違いなく正確なドキュメントをつくるためには、一つひとつの装置・機器の目的、用途や配置される場所などを理解しておく必要があります。当社ではそのほかにはコーサル作業を担当するなど、艦船に関連するキャリアを築いてきました。ここで得た経験と知識を次の世代にしっかりと引き継ぐことを視野に入れ、今後も着実にお任せいただいた業務を全うしていこうと思っています。長崎エンジニアリング部は真面目な社員が多くコミュニケーションも円滑なため、とても働きやすい環境だと思います。やりがいのある仕事ですので、仲間になっていただける方を歓迎します。
        </Text2>
      </Inner>
    </Section>
    <Section id="5">
      <Inner>
        <Head>
          長崎エンジニアリング部<br />
          入社29年目
        </Head>
        <Text1>
          海洋安全に寄与できることを誇りに、<br />
          たゆまぬ努力を続けています
        </Text1>
        <Text2>
          艦船に搭載する機器が故障し交換・修理が必要になった際、部品の情報を調べるために使う「パーツブック」という技術図書作成を担当しています。艦船での任務に就く隊員の方々の負担を減らすために必要な資料なので間違いがあってはならない仕事ですが、少しずつ船の構造がわかっていくことも楽しいですし、いろいろな艦船に携われることもこの仕事の魅力だと思います。これまで担当させていただいた中で最も印象に残っているのは、2代目となる砕氷艦「しらせ」です。小学生の頃に図書館の図鑑で読んだ「しらせ」の後継艦に携わることができたことはとても感慨深く、安全に航行するニュース映像に心躍る思いです。私たちの仕事は、地道に一つひとつずつ積み上げていくものですが、海洋の安全確保や国際秩序の安定に貢献している海上自衛隊、それを支える造船所などの皆様のお役に立てることを誇りに思い、これからも信頼してお任せいただけるよう努力を続けていきたいと思っています。
        </Text2>
      </Inner>
    </Section>
    <Section id="6">
      <Inner>
        <Head>
          長崎エンジニアリング部<br />
          入社27年目
        </Head>
        <Text1>
          これまでに培った知識と経験を通して、<br />
          若手の成長を促進していきたい
        </Text1>
        <Text2>
          コーサルと呼ばれる仕事は、日々造船所に納品される各装置の予備品や付属品などを現地で確認し、艦内搭載分と陸上施設保管分とに分類。それぞれ所定の保管場所に輸送する手配を行う仕事です。主に造船所内での業務となりますが、造船所のご担当者様や自社の若いスタッフと密にコミュニケーションを取りながら、正確かつ効率的に作業を進めています。現在の目標は、勤務歴の浅い若手を一人前に育てること。これから入社される方も含めて、自分が学んできた知識を一つひとつ丁寧に伝えることで、やりがいを持って活躍できるようにサポートをしたいと思っています。マリンソフトの魅力は、誰もが安心して働ける環境が整っていること。ワークライフバランスが取れることが仕事のモチベーションにもつながっています。これからも仲間の力を結集し、仕事に対して誠実に向き合い続けることで、お客様の期待にお応しえたいと思っています。
        </Text2>
      </Inner>
    </Section>
    <Section id="7">
      <Inner>
        <Head>
          神戸エンジニアリング部<br />
          入社24年目
        </Head>
        <Text1>
          1年をかけて完成させる仕事だからこそ、<br />
          他の仕事にはない達成感があります
        </Text1>
        <Text2>
          新しい艦船を建造する際に提出する生産明細書の作成を担当しています。これは、何万点にも及ぶ部品について、様々な情報を分野ごとにまとめたものです。不足している情報については何度も問い合わせするなどして約1年かけて仕上げるため、完成した時の達成感は何事にも代えがたいものがあります。多様なノウハウや知識が必須とされる仕事であり、修得には一定の時間を要しましたし、今でも新たに知り得ることもあります。だからこそやりがいがあり、意義のある仕事だと思います。神戸の事業所は互いを尊重しつつ、言いたいことを言い合える風通しの良い職場です。人間関係が円満なのでストレスなく、安定的に働けることが一番の魅力だと思います。ワークライフバランスの取れた生活を送りたい方にもおすすめです。
        </Text2>
      </Inner>
    </Section>
    <Section id="8">
      <Inner>
        <Head>
          神戸エンジニアリング部<br />
          入社26年目
        </Head>
        <Text1>
          積み上げてきた実績をベースに、<br />
          今後もお客様の信頼を高めていきたい
        </Text1>
        <Text2>
          艦船は、航海中に故障が起きたとしても自動車工場のように修理や部品交換ができないので、あらかじめ予備品や付属品を搭載しておく必要があります。私たちコーサルグループは、それらの物品を艦内に積むものと陸上に保管しておくものとをラベルを貼付しながら仕分けし、資料を整理したうえで、輸送するための準備を行っています。何千点もの物品をお預かりするため決して気の抜けない作業ですが、これまでに大きなミスを起こすことなく積み重ねてきた実績がお客様への信頼につながっていると思います。入社時、艦船に関する知識がない状態からスタートした仕事ですが、今では若手社員の教育を任されています。当面の目標は、新人が業務に必要な知識とスキルを身に付ける手助けをすること。個々のパフォーマンスを高めるサポートを続けることで、少しでも早く独り立ちできるレベルにまで引き上げたいと思っています。専門知識のない未経験からでも充分活躍できるので、これからマリンソフトで働く仲間が増えることも期待しています。
        </Text2>
      </Inner>
    </Section>
    <Section id="9">
      <Inner>
        <Head>
          神戸エンジニアリング部<br />
          入社24年目
        </Head>
        <Text1>
          図書完成という1年後のゴールに向かって<br />
          自分の役割をきちんとこなす
        </Text1>
        <Text2>
          艦船に装備される装置の部品に関する図面などを1冊にまとめたパーツブックという図書を制作しています。文章やデータに誤字脱字はないか、図面との整合性が取れているかなどを確認し、完成まで仕上げる業務を担当しています。パーツブックは数千ページと量が膨大ですが、ただ闇雲にやるのではなく、いくつかの提出期限を目標に進める仕事なのでモチベーションを下げずに取り組めます。完成した図書は1年間の集大成であり、その充足感は特別なものです。神戸の事業所には幅広い世代の方がいますが、どなたも気さくな方ばかり。年上の方も「一緒にやっていこう」というスタンスなので、人間関係や仕事のしやすさという意味でも快適な職場だと思います。自分のペースで根気よく続ける仕事が合っている方に向いている仕事ですし、専門知識がなくても先輩社員が一から丁寧に仕事を教えてくれるので、将来一緒に成長できる仲間が増えてほしいと思っています。
        </Text2>
      </Inner>
    </Section>
  </Outer>
}