import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { TopHead } from '../components/top-head'
import SvgTrustInTheBlue from '../generated/svg/trust-in-the-blue'
import { lrem } from '../styles/lrem'
import { pageGrid } from '../styles/page-grid'
import { useBreakpoint } from '../hooks/use-breakpoint'
import { Mbr } from '../components/br'

const Outer = styled.div`
  margin-top: var(--header-height);
  background-color: #ECF6F9;
  position: relative;
  padding-top: ${lrem(75)};
  @media (max-width: 40rem){
    padding-top: ${lrem(44)};
  }
`

const Inner1 = styled.div`
  ${pageGrid}
  margin-top: ${lrem(35)};
  position: relative;
  z-index: 1;
  @media (max-width: 40rem){
    margin-top: 0;
  }
`

const Head1 = styled.div`
  font-size: ${lrem(50)};
  letter-spacing: 0.05em;
  color: var(--main-color);
  grid-column: 4 / 6;
  font-weight: 700;
  margin-bottom: ${lrem(200)};
  @media (max-width: 40rem){
    font-size: ${lrem(15)};
    margin-bottom: ${lrem(80)};
    margin-top: ${lrem(15)};
    grid-column: 4 / 9;
    text-align: center;
  }
`

const Head2 = styled.div`
  grid-row: 2;
  grid-column: 7 / 9;
  font-size: ${lrem(60)};
  letter-spacing: 0.025em;
  border-bottom: ${lrem(10)} solid var(--main-color);
  color: var(--main-color);
  padding-bottom: ${lrem(30)};
  margin-bottom: ${lrem(130)};
  @media (max-width: 40rem){
    font-size: ${lrem(25)};
    grid-column: 2 / 8;
    text-align: center;
    border-bottom-width: ${lrem(5)};
    grid-row: auto;
    padding-bottom: ${lrem(21)};
    margin-bottom: ${lrem(50)};
  }
  small {
    margin-left: ${lrem(40)};
    font-size: ${lrem(25)};
    @media (max-width: 40rem){
      font-size: ${lrem(12)};
      margin-left: 0;
      display: block;
      margin-top: ${lrem(10)};
    }
  }
`

const Head3 = styled(Head2)`
  grid-row: 1;
  margin-bottom: ${lrem(80)};
  @media (max-width: 40rem){
    grid-column: 2 / 8;
    grid-row: auto;
    padding-bottom: ${lrem(21)};
    margin-bottom: ${lrem(50)};
    margin-top: ${lrem(40)};
  }
`

const Inner1Left = styled.div`
  padding-right: var(--page-center-margin);
  padding-top: ${lrem(110)};
  padding-bottom: ${lrem(130)};
  background-color: #fff;
  grid-column: 1 / 6;
  grid-row: 3;
  border-radius: 0 ${lrem(40)} ${lrem(40)} 0;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 40rem){
    grid-column: 2 / 8;
    grid-row: auto;
    padding: 0;
    margin-left: calc(var(--page-margin) * -1);
    margin-right: calc(var(--page-margin) * -1);
    padding-left: var(--page-margin);
    padding-right: var(--page-margin);
    padding-top: ${lrem(40)};
    margin-top: ${lrem(40)};
    border-radius: 0;
    padding-bottom: ${lrem(40)};
  }
`

const Inner2Left = styled(Inner1Left)`
  background-color: #F0F8FA;
  grid-row: 1 / 3;
  @media (max-width: 40rem){
    grid-column: 2 / 8;
    grid-row: auto;
  }
`

const Text1 = styled.div`
  font-size: ${lrem(21)};
  letter-spacing: 0.025rem;
  line-height: 2;
  width: var(--page-side-content-width);
  @media (max-width: 40rem){
    font-size: ${lrem(13)};
    width: auto;
  }
`


const Inner1Right = styled.div`
  grid-column: 7 / 9;
  grid-row: span 2;
  @media (max-width: 40rem){
    grid-column: 2 / 8;
    grid-row: auto;
  }
`

const Inner2Right = styled(Inner1Right)`
  grid-row: 2 / span 2;
  @media (max-width: 40rem){
    grid-column: 2 / 8;
    grid-row: auto;
  }
`

const Inner2 = styled(Inner1)`
  background: white;
  padding-top: ${lrem(200)};
  padding-bottom: ${lrem(300)};
  @media (max-width: 40rem){
    padding-top: ${lrem(50)};
    padding: 0;
  }
`

const HeadBackground = styled.div`
  color: white;
  position: absolute;
  top: 0;
  width: 100%;
  @media (max-width: 40rem){
    display: none;
  }
  svg {
    width: 100%;
    height: auto;
  }
`

const Text2 = styled.div`
  font-size: ${lrem(26)};
  letter-spacing: 0.02em;
  line-height: 2.5;
  margin: calc(${lrem(26)} * (2.5 - 1) / -2) 0;
  font-weight: 300;
  @media (max-width: 40rem){
    font-size: ${lrem(13)};
    margin-bottom: 0;
  }
  .ja {
    font-size: ${lrem(40)};
    letter-spacing: 0.05em;
    line-height: 1.6;
    display: block;
    color: var(--main-color);
    white-space: nowrap;
    font-weight: 500;
    @media (max-width: 40rem){
      font-size: ${lrem(20)};
    }
  }
  .en {
    margin-top: ${lrem(51)};
    font-size: ${lrem(30)};
    line-height: 1.8333;
    letter-spacing: 0.025em;
    display: block;
    color: var(--main-color);
    margin-bottom: ${lrem(124)};
    @media (max-width: 40rem){
      font-size: ${lrem(14)};
      margin-top: ${lrem(25)};
      margin-bottom: ${lrem(25)};
    }
  }
`

const ImageContainer1 = styled.div`
  margin-top: ${lrem(170)};
  @media (max-width: 40rem){
    margin-top: 0;
  }
  img {
    display: block;
    width: 100%;
    height: auto;
  }
`

const ImageContainer2 = styled(ImageContainer1)`
  grid-column: 3 / 6;
  @media (max-width: 40rem){
    grid-column: 1 / -1;
    position: relative;
  }

`

const ImageCaption = styled.div`
  font-size: ${lrem(16)};
  letter-spacing: 0.02em;
  margin-top: ${lrem(30)};
  text-align: right;
  @media (max-width: 40rem){
    position: absolute;
    font-size: ${lrem(10)};
    bottom: ${lrem(10)};
    left: 0;
    width: 100%;
    color: white;
    padding: 1rem;
    box-sizing: border-box;
    padding: 0 var(--page-margin);
  }
`

const ImageCaptionLeft = styled(ImageCaption)`
  text-align: left;
`

const Sign = styled.div`
  font-size: ${lrem(26)};
  letter-spacing: 0.035em;
  line-height: 2.8;
  margin-top: ${lrem(160)};
  @media (max-width: 40rem){
    margin-top: ${lrem(49)};
    font-size: ${lrem(13)};
  }
  b {
    font-size: ${lrem(40)};
    line-height: 1.75;
    @media (max-width: 40rem){
      font-size: ${lrem(20)};
      display: block;
      margin-bottom: ${lrem(-10)};
    }
  }
`

const MobileImageContainer = styled.div`
  grid-column: 1 / -1;
  position: relative;
  img {
    width: 100%;
    height: auto;
  }
`


type AboutSection1Props = {

}
export const AboutSection1: FunctionComponent<AboutSection1Props> = () => {
  const isMobile = useBreakpoint('40rem')
  return <Outer>
    <TopHead>ABOUT US</TopHead>
    <HeadBackground>
      <SvgTrustInTheBlue />
    </HeadBackground>
    <Inner1>
      <Head1>私たちについて</Head1>
      <Head2>
        MISSION
        <small>
          ［マリンソフトの使命］
        </small>
      </Head2>
      <Inner1Right>
        <Text2>
          マリンソフトは、海上自衛隊が保有する艦船の安全航行と維持整備活動の支援を目的として幅広いエンジニアリングサービス事業を展開しています。その内容は、艦船の運用に関する取扱説明書をはじめとする技術図書のほか、ソフトウェア開発や完成図書の電子化、検査・修理情報の分析・評価、予備品・付属品の管理、 応急時の支援ツールに至るまで、その製品・サービスは 多岐にわたります。青い海での使命を担うお客様の信頼にお応えするために、マリンソフトはこれからもお客様の声と現場に向き合い、 実用性に優れた各種ソリューションを提案していきます。
        </Text2>
        {
          isMobile ||
          <ImageContainer1>
            <img src="/images/0006.jpg" width="1380" height="920" />
            <ImageCaption>写真提供：海上自衛隊</ImageCaption>
          </ImageContainer1>
        }
      </Inner1Right>
      <Inner1Left>
        <Text1>
          Marine soft Co., Ltd. is engaged in a wide range of engineering services to support the safe navigation and maintenance activities of ships owned by the Maritime Self-Defense Force. Our products and services range from instruction manuals and other technical books for ship operations, to software development, digitization of technical documents, analysis and evaluation of inspection and maintenance information, management of spare parts and accessories, as well as the support tools for the damage control.
          To respond to the trust of our customers who carry out their mission in the blue sea, Marine soft will continue to meet the needs of our customers and worksites, and will propose various practical solutions.
        </Text1>
      </Inner1Left>
      {
        isMobile && 
        <MobileImageContainer>
          <img src="/images/0006.jpg" width="1380" height="920" />
          <ImageCaption>写真提供：海上自衛隊</ImageCaption>
        </MobileImageContainer>
      }
    </Inner1>
    <Inner2 id="2">
      <Head3>
        TOP MESSAGE
        <small>
          ［代表挨拶］
        </small>
      </Head3>
      <Inner2Right>
        <Text2>
          <span className="ja">
            お客様との信頼をベースに<br />
            高水準な製品・サービスを<Mbr />提供していく
          </span>
          <span className="en">
            We will provide high-level products and 
            services based on the trust of our customers.
          </span>
          当社は、艦船の運用に必要なドキュメント類を制作するマリンソフト株式会社と予備品の整備補給業務を担う日本エンジニアリング株式会社が合併し、2022年7月より新生マリンソフト株式会社として新たな船出を切りました。<br />
          海上自衛隊の水上艦、潜水艦の取扱説明書をはじめとする多種多様なマニュアル、図書類を作成するとともに近年では、さまざまなフィールドデータを分析することで艦船の修理・点検に役立てる維持整備管理事業、予備品の仕分け・管理を行うコーサル作業など、多角的な側面から艦船の運用・維持整備・管理業務をサポートしています。長きにわたり、これらの重要な業務をお任せいただけたのも、すべてはお客様のご期待に応える中で一つひとつ信頼を積み重ねてきた結果であり、今後もお客様との信頼関係をより一層深めていくために真摯な努力を続けていく所存です。<br />
          デジタル技術の進展によって、艦船の運用にもより高度かつ専門的な技術とノウハウが必要とされています。私たちは時代の変化に柔軟に対応し新しい技術をいち早く取り入れながら、ユーザビリティに主眼を置いた顧客満足度の高い製品・サービスの提供に努めてまいります。皆様には引き続きご期待いただくとともに、今後も変わらぬご支援を賜りますようお願い申し上げます。
          <Sign>
            代表取締役社長／President & CEO<br />
            <b>新宮 弘喜</b> Hiroki Shingu
          </Sign>
        </Text2>
      </Inner2Right>
      <Inner2Left>
        <Text1>
          Marine soft Co., Ltd. was formed through the merger of Marine soft Co., Ltd., which produces documents necessary for the operation of naval ships, and Nippon Engineering Co., Ltd., which is responsible for maintenance and supply of spare parts , and has embarked on a new journey as the new Marine soft Co., Ltd. in July 2022.<br />
          We produce a wide variety of manuals and documents, including instruction manuals for naval surface ships and submarines of the Japan Maritime Self-Defense Force. In recent years, we have also been supporting naval ships operations, maintenance, and management from a multifaceted perspective, including maintenance management business that analyzes various field data to help repair and inspect ships, and COSAL (Coordinated Shipboard Allowance List) work that sorts and manages spare parts. The fact that we have been entrusted with these important operations for so long is entirely due to the trust we have built up one by one in meeting the expectations of our customers. We will continue to make diligent efforts to further deepen the relationship of trust with our customers. With the advancement of digital technology, more advanced and specialized technology and know-how are required for the operation of naval ships. We will strive to provide products and services with a high level of customer satisfaction, focusing on usability, while responding flexibly to the changes of the times and quickly incorporating new technologies. We look forward to your continued support and patronage.
        </Text1>
      </Inner2Left>
      <ImageContainer2>
        <img src="/images/0007.jpg" width="1580" height="1054" />
        <ImageCaptionLeft>写真提供：海上自衛隊</ImageCaptionLeft>
      </ImageContainer2>
    </Inner2>
  </Outer>
}