import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { lrem } from '../styles/lrem'
import { pageGrid } from '../styles/page-grid'

const Outer = styled.div`
  background-image: linear-gradient(54deg, rgba(126,197,214,0.3) 14%, rgba(192,192,227,0.3) 43%, rgba(222,250,255,0.3) 73%);
  ${pageGrid}
  padding-top: ${lrem(192)};
  padding-bottom: ${lrem(192)};
  @media (max-width: 40rem){
    padding-top: ${lrem(39)};
    padding-bottom: ${lrem(71)};
  }
`

const ImageContainer = styled.div`
  grid-column: 2 / 10;
  display: flex;
  justify-content: center;
  img {
    display: block;
    width: ${lrem(950)};
    height: auto;
    @media (max-width: 40rem){
      width: ${lrem(228)};
    }
  }
`

const Inner = styled.div`
  grid-column: 4 / 9;
  margin-top: ${lrem(200)};
  @media (max-width: 40rem){
    margin-top: ${lrem(30)};
  }
`

const Section = styled.div`
  & + & {
    margin-top: ${lrem(100)};
    padding-top: ${lrem(100)};
    border-top: 1px solid currentColor;
    @media (max-width: 40rem){
      margin-top: ${lrem(35)};
      padding-top: ${lrem(35)};
    }
  }
`

const Head = styled.div`
  font-size: ${lrem(100)};
  font-family: 'Lato';
  font-weight: 300;
  @media (max-width: 40rem){
    font-size: ${lrem(30)};
    font-weight: 300;
  }
  &::first-letter {
    color: var(--main-color);
  }
  span {
    font-size: ${lrem(30)};
    letter-spacing: 0.05em;
    margin-left: ${lrem(55)};
    @media (max-width: 40rem){
      font-size: ${lrem(14)};
      margin-left: ${lrem(16)};
    }
  }
`

const Text1 = styled.div`
  margin-top: ${lrem(65)};
  font-size: ${lrem(26)};
  letter-spacing: 0.025em;
  line-height: 2.5;
  @media (max-width: 40rem){
    font-size: ${lrem(13)};
    margin-top: ${lrem(25)};
    line-height: 2.25;
  }
`

const Text2 = styled.div`
  margin-top: ${lrem(50)};
  font-size: ${lrem(21)};
  line-height: 2.28571428571;
  @media (max-width: 40rem){
    font-size: ${lrem(13)};
    margin-top: ${lrem(25)};
    line-height: 2;
  }
`

type FeaturesSection2Props = {

}
export const FeaturesSection2: FunctionComponent<FeaturesSection2Props> = () => {
  return <Outer>
    <ImageContainer>
      <img src="/images/0014.png" width="1900" height="1900" />
    </ImageContainer>
    <Inner>
      <Section>
        <Head>
          Skillful<span>高度な専門知識</span>
        </Head>
        <Text1>
          艦船の「設計・建造」「運用・管理」「維持整備・技術管理」、予備品の「搭載・管理」など、高度な専門分野に特化したエキスパートが結集。豊富な知識・経験に最新デジタル技術を組み合わせた提案によって、お客様の課題解決に貢献します。
        </Text1>
        <Text2>
          Experts in highly specialized fields such as “design and construction”, “operation and management”, “maintenance and technical management”, and “mounting and management of spare parts” for naval ships are gathered. We contribute to solving our customers’ problems by offering proposals that combine our wealth of knowledge and experience with the latest digital technology.
        </Text2>
      </Section>
      <Section>
        <Head>
          Sight of User’s<span>ユーザー視点</span>
        </Head>
        <Text1>
          実際の現場で使用するユーザの視点に立ち、「わかりやすさ」と「使い勝手」に主眼を置いた各種ドキュメントを作成。アニメーションやイラスト等のビジュアルを多用することで、効率的に必要な情報を取得できる表現を追求しています。
        </Text1>
        <Text2>
          From the viewpoint of users who actually use the system, we create various documents with a focus on “ease of understanding” and “usability.” By making extensive use of visuals such as animation and illustrations, we are pursuing expressions that allow users to efficiently obtain the necessary information.
        </Text2>
      </Section>
      <Section>
        <Head>
          Security<span>強固な情報セキュリティ</span>
        </Head>
        <Text1>
          海上自衛隊艦船の運用と管理に関わるため、万全の保全対策を実施。電子錠、電子センサーなどの設備だけでなく、社員の情報セキュリティ意識の向上を図ることで、ソフト・ハード両面から強靭なセキュリティ体制を維持しています。
        </Text1>
        <Text2>
          As we are involved in the operation and management of Maritime Self-Defense Force ships, we have implemented thorough information security measures. We maintain a robust security system in terms of both software and hardware, not only through the use of electronic locks, electronic sensors, and other equipment, but also by raising information security awareness among employees.
        </Text2>
      </Section>
    </Inner>
  </Outer>
}