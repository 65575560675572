import * as React from "react";
import type { SVGProps } from "react";
const SvgMenuIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={14}
    data-name="\u30EC\u30A4\u30E4\u30FC 1"
    viewBox="0 0 14 14"
    {...props}
  >
    <g fill="currentcolor" data-name="Group 1436">
      <path d="M14 2.5H0v-1h14v1Z" data-name="Line 312" />
      <path d="M14 7.5H0v-1h14v1Z" data-name="Line 313" />
      <path d="M14 12.5H0v-1h14v1Z" data-name="Line 314" />
    </g>
  </svg>
);
export default SvgMenuIcon;
