import React from 'react';
import { FunctionComponent } from 'react';
import { createGlobalStyle } from 'styled-components';
import { useExternalCss } from '../hooks/use-external-css';
import { lrem } from '../styles/lrem';

const Css = createGlobalStyle`
  :root {
    --main-color: #7EC1D6;
    --text-color: #575454;
    --base-width: 1920;
    --base-ratio: 1;
    --page-margin: ${lrem(40)};
    --page-max-width: ${lrem(1920)};
    --page-max-content-width: calc(var(--page-max-width) - var(--page-margin) * 2);
    --page-side-margin1: ${lrem(140)};
    --page-side-margin2: ${lrem(200)};
    --page-side-content-width: ${lrem(470)};
    --page-center-margin: ${lrem(100)};
    --header-height: ${lrem(120)};
    @media (max-width: 40rem){
      --base-width: 375;
      --base-ratio: 2;
      --page-margin: ${lrem(40)};
      --page-max-width: ${lrem(375)};
      --page-max-content-width: calc(var(--page-max-width) - var(--page-margin) * 2);
      --page-side-margin1: ${lrem(0)};
      --page-side-margin2: ${lrem(0)};
      --page-side-content-width: calc(var(--page-max-content-width) / 2);
      --page-center-margin: ${lrem(0)};
      --header-height: ${lrem(40)};
    }

    color: var(--text-color);
    font-family: 'Lato', 'Noto Sans JP', sans-serif;
  }
  a {
    color: inherit;
    text-decoration: inherit;
  }
  img {
    pointer-events: none;
  }
`

export const RootCss: FunctionComponent = () => {
  useExternalCss('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700&family=Noto+Sans+JP:wght@300;400;700&display=swap')
  return <Css />
}