import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { AboutSection1 } from '../sections/about-section1'
import { AboutSection2 } from '../sections/about-section2'
import { AboutSection3 } from '../sections/about-section3'

const Outer = styled.div``

const Inner = styled.div``

type AboutPageProps = {

}
export const AboutPage: FunctionComponent<AboutPageProps> = () => {
  return <Outer>
    <Inner id="1">
      <AboutSection1 />
    </Inner>
    <Inner id="3">
      <AboutSection2 />
    </Inner>
    <Inner >
      <AboutSection3 />
    </Inner>
  </Outer>
}