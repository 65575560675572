import React, { FunctionComponent, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import { Link } from 'react-router-dom'
import SvgLogo from '../generated/svg/logo'
import { pageMaxContentWidth } from '../styles/page-max-width'
import { lrem } from '../styles/lrem'
import SvgMenuIcon from '../generated/svg/menu-icon'
import SvgCloseIcon from '../generated/svg/close-icon'
import { MobileMenu } from './mobile-menu'
import { useBreakpoint } from '../hooks/use-breakpoint'
import SvgLogoMobile from '../generated/svg/logo-mobile'

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const Outer = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 100;
  background-color: #fffd;
  /* animation: ${fadeIn} 2s 4s both; */
`

const Inner = styled.div`
  ${pageMaxContentWidth}
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: ${lrem(20)};
  padding-bottom: ${lrem(20)};
  @media (max-width: 40rem){
    padding-left: ${lrem(10)};
    padding-right: ${lrem(10)};
    padding-top: 0;
    padding-bottom: 0;
    height: var(--header-height);
    margin-left: auto;
    margin-right: auto;
    max-width: none;
  }
`

const LogoContainer = styled(Link)`
  display: block;
  svg {
    display: block;
    height: ${lrem(80)};
    width: auto;
    @media (max-width: 40rem){
      height: ${lrem(27)};
    }
  }
`

const NavigatorOuter = styled.div`
  display: flex;
  @media (max-width: 40rem){
    display: none;
  }
`

const NavigatorItem = styled(Link)`
  font-weight: 700;
  color: #7EC1D6;
  font-size: ${lrem(22)};
  padding: ${lrem(15)};
`

const MenuButton = styled.div`
  cursor: pointer;
  padding: ${lrem(10)};
  margin: ${lrem(-10)};
  display: none;
  @media (max-width: 40rem){
    display: block;
  }
  svg {
    display: block;
    height: ${lrem(18)};
    width: auto;
  }
`

const MobileMenuContainer = styled.div`
`



type HeaderProps = {

}
export const Header: FunctionComponent<HeaderProps> = () => {
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const isMobile = useBreakpoint('40rem')
  return <>
    <Outer>
      <Inner>
        <LogoContainer to="/">
          {
            isMobile ?
            <SvgLogoMobile /> :
            <SvgLogo />
          }
        </LogoContainer>
        <NavigatorOuter>
          <NavigatorItem to="/">TOP</NavigatorItem>
          <NavigatorItem to="/about">ABOUT US</NavigatorItem>
          <NavigatorItem to="/features">FEATURES</NavigatorItem>
          <NavigatorItem to="/products">PRODUCTS & SERVICES</NavigatorItem>
          <NavigatorItem to="/voices">VOICE</NavigatorItem>
          <NavigatorItem to="/recruit">RECRUIT</NavigatorItem>
          <NavigatorItem to="/contact">CONTACT</NavigatorItem>
        </NavigatorOuter>
        <MenuButton onClick={() => setMenuIsOpen(v => !v)}>
          {
            menuIsOpen ? <SvgCloseIcon /> : <SvgMenuIcon />
          }
        </MenuButton>
      </Inner>
    </Outer>
    <MobileMenuContainer>
      <MobileMenu show={menuIsOpen} onClose={() => setMenuIsOpen(false)}/>
    </MobileMenuContainer>
  </>
}