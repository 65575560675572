import React, { Fragment, FunctionComponent } from 'react'
import styled from 'styled-components'
import { TopHead } from '../components/top-head'
import { lrem } from '../styles/lrem'
import { pageGrid } from '../styles/page-grid'
import { useRecruitQuery } from '../generated/graphql'

const Outer = styled.div`
  padding-top: ${lrem(181)};
  padding-bottom: ${lrem(400)};
  @media (max-width: 40rem){
    padding-top: calc(${lrem(44)} + var(--header-height));
    padding-bottom: ${lrem(120)};
  }
`

const Inner1 = styled.div`
  ${pageGrid}
`

const Head1 = styled.div`
  font-size: ${lrem(50)};
  letter-spacing: 0.05em;
  color: var(--main-color);
  grid-column: 4 / 6;
  font-weight: 700;
  margin-bottom: ${lrem(200)};
  @media (max-width: 40rem){
    font-size: ${lrem(15)};
    margin-top: ${lrem(15)};
    grid-column: 4 / 9;
    text-align: center;
    margin-bottom: ${lrem(40)};
  }
`

const Inner2 = styled.div`
  width: ${lrem(800)};
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 40rem){
    width: 100%;
    padding-left: var(--page-margin);
    padding-right: var(--page-margin);
    box-sizing: border-box;
  }
`

const Head2 = styled.div`
  width: fit-content;
  padding: 0 ${lrem(40)};
  font-size: ${lrem(80)};
  letter-spacing: 0.025em;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  margin-bottom: ${lrem(130)};
  @media (max-width: 40rem){
    font-size: ${lrem(25)};
    margin-bottom: ${lrem(60)};
  }
  &::before {
    background-color: var(--main-color);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 80%;
    transform: translateY(60%);
    content: '';
    display: block;
    z-index: -1;
  }
`

const Section = styled.div`
  & + & {
    margin-top: ${lrem(60)};
    padding-top: ${lrem(60)};
    border-top: 1px solid currentColor;
  }
`

const Title = styled.div`
  font-size: ${lrem(30)};
  letter-spacing: 0.05em;
  font-weight: 700;
  @media (max-width: 40rem){
    font-size: ${lrem(20)};
  }
`

const Text = styled.div`
  font-size: ${lrem(24)};
  letter-spacing: 0.02em;
  line-height: 2;
  margin-top: ${lrem(40)};
  white-space: pre-wrap;
  @media (max-width: 40rem){
    font-size: ${lrem(16)};
    margin-top: ${lrem(20)};
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: max-content max-content 1fr;
`

const Cell = styled.div`
`
const JustifyCell = styled(Cell)`
  display: flex;
  justify-content: space-between;
  span {
    display: block;
  }
`

type RecruitSection1Props = {

}
export const RecruitSection1: FunctionComponent<RecruitSection1Props> = () => {
  const recruitQuery = useRecruitQuery()
  return <Outer>
    <TopHead>RECRUIT</TopHead>
    <Inner1>
      <Head1>採用情報</Head1>
    </Inner1>
    <Inner2>
      <Head2>RECRUITMENT</Head2>
      {
        recruitQuery.data?.page?.recruitFields?.type?.map((type, i) => {
          return <Fragment key={i}>
            <Section>
              <Title>
                {type?.title}
              </Title>
              <Text>
                {type?.beforeTable}
                <Grid>
                  {type?.table?.map((row, i) => {
                    return <Fragment key={i}>
                      <JustifyCell>
                        {row?.label?.split('').map((str, i) => {
                          return <span key={i}>{str}</span>
                        })}
                      </JustifyCell>
                      <Cell>：</Cell>
                      <Cell>{row?.value}</Cell>
                    </Fragment>
                  })}
                </Grid>
                {type?.afterTable}
              </Text>
            </Section>
          </Fragment>
        })
      }
    </Inner2>
  </Outer>
}