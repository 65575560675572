import { css } from 'styled-components';
import { lrem } from './lrem';

export const gridBackground = css`
  background-size: ${lrem(80)} ${lrem(80)};
  background-image:
    linear-gradient(to right, #BDDFE9 1px, transparent 1px),
    linear-gradient(to bottom, #BDDFE9 1px, transparent 1px)
  ;
  @media (max-width: 40rem){
    background-size: ${lrem(30)} ${lrem(30)};
  }
`