import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { TopHead } from '../components/top-head'
import { lrem } from '../styles/lrem'
import { LinkButton } from '../components/button'
import { Mbr } from '../components/br'

const Outer = styled.div`
  background-image: url(/images/0005.jpg);
  background-size: cover;
  padding: ${lrem(140)} 0;
  @media (max-width: 40rem){
    padding: ${lrem(27)} 0 ${lrem(84)};
  }
`

const Inner = styled.div`
  padding: calc(var(--page-margin) + var(--page-side-margin1));
  margin-left: auto;
  margin-right: auto;
  max-width: var(--page-max-width);
  box-sizing: border-box;
  @media (max-width: 40rem){
    padding-top: ${lrem(71)};
  }
`

const Text1 = styled.div`
  font-size: ${lrem(130)};
  color: white;
  font-family: 'Lato';
  font-weight: 500;
  @media (max-width: 40rem){
    font-size: ${lrem(30)};
    text-align: center;
  }
  span {
    color: var(--main-color);
    font-size: ${lrem(200)};
    @media (max-width: 40rem){
      font-size: inherit;
    }
  }
`

const CircleContainer = styled.div`
  margin-top: ${lrem(100)};
  display: flex;
  width: ${lrem(1310)};
  @media (max-width: 40rem){
    margin-top: ${lrem(36)};
    width: var(--page-max-content-width);
  }
`

const CircleItem = styled.div`
  aspect-ratio: 1 / 1;
  border: 1px solid white;
  flex: 1 1 0;
  border-radius: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &:not(:first-child){
    margin-left: ${lrem(-20)};
    @media (max-width: 40rem){
      margin-left: ${lrem(-7)};
    }
  }
`

const CircleText1 = styled.div`
  font-size: ${lrem(70)};
  font-weight: 300;
  color: white;
  font-family: 'Lato';
  text-align: center;
  margin-bottom: ${lrem(86)};
  line-height: 1.25;
  @media (max-width: 40rem){
    font-size: ${lrem(15)};
    margin-bottom: ${lrem(9)};
  }
  &::first-letter {
    color: var(--main-color);
  }
  &:has(br) {
    margin-top: ${lrem(-30)};
    margin-bottom: ${lrem(40)};
    @media (max-width: 40rem){
      margin-top: 0;
      margin-bottom: ${lrem(9)};
    }
  }
`

const CircleText2 = styled.div`
  font-size: ${lrem(30)};
  color: white;
  font-weight: 700;
  @media (max-width: 40rem){
    font-size: ${lrem(9)};
    line-height: 1.75;
    text-align: center;
  }
`

const FeaturesButton = styled(LinkButton)`
  background-color: white;
  color: var(--text-color);
`

const ButtonContainer = styled.div`
  margin-top: ${lrem(200)};
  @media (max-width: 40rem){
    margin-top: ${lrem(52)};
  }
`


type TopFeaturesSectionProps = {
}
export const TopFeaturesSection: FunctionComponent<TopFeaturesSectionProps> = () => {
  return <Outer>
    <TopHead position="right" withBar color="white">FEATURES</TopHead>
    <Inner>
      <Text1>Our Values = <span>3S</span></Text1>
      <CircleContainer>
        <CircleItem>
          <CircleText1>
            Skillful
          </CircleText1>
          <CircleText2>
            高度な<Mbr />専門知識
          </CircleText2>
        </CircleItem>
        <CircleItem>
          <CircleText1>
            Sight<br />
            of User’s
          </CircleText1>
          <CircleText2>
            ユーザー視点
          </CircleText2>
        </CircleItem>
        <CircleItem>
          <CircleText1>
            Security
          </CircleText1>
          <CircleText2>
            強固な<Mbr />情報セキュリティ
          </CircleText2>
        </CircleItem>
      </CircleContainer>
      <ButtonContainer>
        <FeaturesButton to="/features">FEATURES &gt;</FeaturesButton>
      </ButtonContainer>
    </Inner>
  </Outer>
}