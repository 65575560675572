import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { TopHead } from '../components/top-head'
import { lrem } from '../styles/lrem'
import { gridBackground } from '../styles/grid-background'
import { LinkButton } from '../components/button'

const Outer = styled.div`
  padding-top: ${lrem(124)};
  padding-bottom: ${lrem(233)};
  ${gridBackground}
  @media (max-width: 40rem){
    padding-top: ${lrem(32)};
    padding-bottom: ${lrem(32)};
  }
`

const Inner = styled.div`
  width: calc(var(--page-max-content-width) - var(--page-side-margin1) * 2);
  padding-left: var(--page-side-margin2);
  margin-left: auto;
  margin-right: auto;
  padding-top: ${lrem(80)};
  box-sizing: border-box;
  @media (max-width: 40rem){
    padding-top: ${lrem(20)};
  }
`

const ImageContainer = styled.div`
  margin: 0 calc(var(--page-margin) * -1);
  img {
    display: block;
    width: 100%;
    height: 100%;
  }
`

const Text1 = styled.div`
  font-size: ${lrem(60)};
  letter-spacing: 0.035em;
  line-height: 2;
  font-weight: 300;
  margin-top: ${lrem(100)};
  @media (max-width: 40rem){
    font-size: ${lrem(20)};
    margin-top: ${lrem(24)};
  }
`

const Text2 = styled.div`
  font-size: ${lrem(35)};
  letter-spacing: 0.035em;
  margin-top: ${lrem(30)};
  @media (max-width: 40rem){
    font-size: ${lrem(15)};
    line-height: 1.75;
    margin-top: ${lrem(15)};
  }
`

const ButtonContainer = styled.div`
  margin-top: ${lrem(125)};
  display: flex;
  justify-content: flex-end;
  @media (max-width: 40rem){
    margin-top: ${lrem(30)};
  }
`


type TopProductsSectionProps = {

}
export const TopProductsSection: FunctionComponent<TopProductsSectionProps> = () => {
  return <Outer>
    <TopHead withBar>PRODUCTS & SERVICES</TopHead>
    <Inner>
      <ImageContainer>
        <img src="/images/0004.jpg" width="2772" height="1814" />
      </ImageContainer>
      <Text1>
        マリンソフトが提供する<br />
        製品・サービスをご紹介します
      </Text1>
      <Text2>
        Introducing the products and services provided by Marine soft.
      </Text2>
      <ButtonContainer>
        <LinkButton to="/products">PRODUCTS & SERVICES &gt;</LinkButton>
      </ButtonContainer>
    </Inner>
  </Outer>
}