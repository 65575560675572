import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { FeaturesSection1 } from '../sections/features-section1'
import { FeaturesSection2 } from '../sections/features-section2'
import { FeaturesSection3 } from '../sections/features-section3'

const Outer = styled.div``

const Inner = styled.div``

type FeaturesPageProps = {

}
export const FeaturesPage: FunctionComponent<FeaturesPageProps> = () => {
  return <Outer>
    <Inner>
      <FeaturesSection1/>
    </Inner>
    
    <Inner id="1">
      <FeaturesSection2/>
    </Inner>
    
    <Inner>
      <FeaturesSection3/>
    </Inner>
    
  </Outer>
}