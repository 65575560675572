import React, { Fragment, FunctionComponent } from 'react'
import styled from 'styled-components'
import { lrem } from '../styles/lrem'
import { Mbr } from '../components/br'
import { useOfficeQuery } from '../generated/graphql'

const Outer = styled.div`
  background-color: #ECF6F9;
  padding-top: ${lrem(225)};
  padding-bottom: ${lrem(500)};
  @media (max-width: 40rem){
    padding-top: ${lrem(40)};
    padding-bottom: ${lrem(40)};
    background-color: transparent;
  }
`

const Inner = styled.div`
  max-width: var(--page-max-width);
  margin-left: auto;
  margin-right: auto;
  padding-left: calc(var(--page-side-margin1) + var(--page-side-margin2));
  padding-right: calc(var(--page-side-margin1));
  box-sizing: border-box;
  @media (max-width: 40rem){
  }
`

const Inner2 = styled.div`
  background-color: #fff;
  padding: ${lrem(82)} ${lrem(52)} ${lrem(82)} ${lrem(65)};
  @media (max-width: 40rem){
    padding-left: var(--page-margin);
    padding-right: var(--page-margin);
    padding-top: 0;
    padding-bottom: 0;
    max-width: none;
  }
`

const Item = styled.div`
  border-left: ${lrem(10)} solid var(--main-color);
  padding-left: ${lrem(35)};
  @media (max-width: 40rem){
    padding-left: ${lrem(25)};
    border-left-width: ${lrem(5)};
  }
`

const Divider = styled.div`
  margin-top: ${lrem(75)};
  margin-bottom: ${lrem(75)};
  border-top: 1px solid currentColor;
  &:last-child {
    display: none;
  }
  @media (max-width: 40rem){
    margin-top: ${lrem(60)};
    margin-bottom: 0;
    border-top: 0 none;
  }
`

const Name = styled.div`
  font-size: ${lrem(45)};
  color: var(--main-color);
  @media (max-width: 40rem){
    font-size: ${lrem(22)};
  }
  small {
    font-size: ${lrem(24)};
    color: var(--text-color);
    margin-left: ${lrem(20)};
    @media (max-width: 40rem){
      font-size: ${lrem(13)};
    }
  }
`

const Container = styled.div`
  display: flex;
  gap: ${lrem(33)};
  margin-top: ${lrem(40)};
  @media (max-width: 40rem){
    flex-direction: column;
    margin-top: ${lrem(15)};
  }
`

const Left = styled.div`
  flex: 1 1 0;
`

const Right = styled.div`
  flex: 0 0 ${lrem(385)};
  display: flex;
  flex-direction: column;
  gap: ${lrem(20)};
  @media (max-width: 40rem){
    gap: ${lrem(10)};
    flex: 0 0 auto;
  }
  img {
    display: block;
    width: 100%;
    height: auto;
  }
`

const Text1 = styled.div`
  font-size: ${lrem(23)};
  line-height: ${lrem(44)};
  font-weight: 300;
  white-space: pre-wrap;
  @media (max-width: 40rem){
    font-size: ${lrem(10)};
    line-height: 2;
  }
`

const Text2 = styled.div`
  font-size: ${lrem(22)};
  line-height: ${lrem(40)};
  letter-spacing: 0.025em;
  margin-top: ${lrem(20)};
  font-weight: 300;
  white-space: pre-wrap;
  @media (max-width: 40rem){
    font-size: ${lrem(10)};
    line-height: 2;
    margin-top: ${lrem(10)};
  }
`

const Text3 = styled.div`
  font-size: ${lrem(23)};
  line-height: ${lrem(44)};
  margin-top: ${lrem(37)};
  padding-top: ${lrem(37)};
  border-top: 1px solid currentColor;
  font-weight: 300;
  white-space: pre-wrap;
  @media (max-width: 40rem){
    font-size: ${lrem(10)};
    line-height: 2;
    padding-top: ${lrem(20)};
    margin-top: ${lrem(20)};
  }
`

const Text4 = styled.div`
  font-size: ${lrem(20)};
  line-height: ${lrem(34)};
  margin-top: ${lrem(20)};
  font-weight: 300;
  white-space: pre-wrap;
  @media (max-width: 40rem){
    font-size: ${lrem(10)};
    line-height: 2;
    margin-top: ${lrem(10)};
  }
`

type AboutSection3Props = {

}
export const AboutSection3: FunctionComponent<AboutSection3Props> = () => {
  const officeQuery = useOfficeQuery()
  return <Outer>
    <Inner>
      <Inner2>
        {
          officeQuery.data?.page?.officeFields?.office?.map((office, i) => {
            return <Fragment key={i}>
              <Item>
                <Name>
                  {office?.name}
                  <small>{office?.department}</small>
                </Name>
                <Container>
                  <Left>
                    <Text1>
                      {office?.addressJa}
                    </Text1>
                    <Text2>
                      {office?.descriptionJa}
                    </Text2>
                    <Text3>
                      {office?.addressEn}
                    </Text3>
                    <Text4>
                      {office?.descriptionEn}
                    </Text4>
                  </Left>
                  <Right>
                    {
                      office?.images?.map((image, i) => {
                        return <img src={image?.image?.mediaItemUrl ?? ''} width={image?.image?.mediaDetails?.width ?? ''} height={image?.image?.mediaDetails?.height ?? ''} />
                      })
                    }
                  </Right>
                </Container>
              </Item>
              <Divider />
            </Fragment>
          })
        }
      </Inner2>
    </Inner>

  </Outer>
}