import { css } from 'styled-components';

export const pageGrid = css`
  display: grid;
  grid-template-columns:
    minmax(var(--page-margin), 1fr)
    var(--page-side-margin1)
    var(--page-side-margin2)
    var(--page-side-content-width)
    var(--page-center-margin)
    var(--page-center-margin)
    var(--page-side-content-width)
    var(--page-side-margin2)
    var(--page-side-margin1)
    minmax(var(--page-margin), 1fr);
`