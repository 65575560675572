  import React, { FunctionComponent, createRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { lrem } from '../styles/lrem'
import { Button } from './button'
import classNames from 'classnames'

const Outer = styled.div`
  padding-bottom: ${lrem(400)};
  scroll-margin-block-start: ${lrem(200)};
`

const Container = styled.div`
  & + & {
    margin-top: ${lrem(60)};
    @media (max-width: 40rem){
      margin-top: ${lrem(30)};
    }
  }
;`

const Label1 = styled.div`
  font-size: ${lrem(24)};
  line-height: 1.6666;
  letter-spacing: 0.02em;
  @media (max-width: 40rem){
    font-size: ${lrem(13)};
  }
`

const Label2 = styled(Label1)``


const Required = styled.span`
  font-size: 0.8em;
  color: #f00;
  margin-left: ${lrem(16)};
  @media (max-width: 40rem){
    margin-left: ${lrem(10)};
  }
  .confirming & {
    display: none;
  }
  &::before {
    content: '*';
  }
`
const inputStyle = css`
  box-sizing: border-box;
  width: 100%;
  font-size: max(${lrem(24)}, 1rem);
  padding: ${lrem(8)} ${lrem(16)};
  margin-top: ${lrem(16)};
  display: block;
  line-height: 1.75;
  border: 1px solid currentColor;
  &[disabled] {
    border: 0 none;
  }
  @media (max-width: 40rem){
    font-size: max(${lrem(13)}, 1rem);
    padding: ${lrem(8)};
    margin-top: 0;
  }
`

const Input = styled.input`
  ${inputStyle}
`


const TextareaContainer = styled.div`
  position: relative;
`

const TextareaSizer = styled.div`
  ${inputStyle}
  white-space: pre-wrap;
  pointer-events: none;
  min-height: 1em;
  visibility: hidden;
  min-height: ${lrem(300)};
  .confirming & {
    min-height: 0;
  }
  &::after {
    content: ' ';
  }
`

const Textarea = styled.textarea`
  ${inputStyle}
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  margin-top: 0;
  resize: none;
  @media (max-width: 40rem){
    
  }
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`

const SendButton = styled.button`
  margin-top: ${lrem(60)};
  font-size: ${lrem(24)};
  letter-spacing: 0.02em;
  line-height: 1.6666;
  border: none;
  color: white;
  text-align: center;
  padding: ${lrem(25)};
  background-color: var(--main-color);
  width: 100%;
  display: block;
  cursor: pointer;
  @media (max-width: 40rem){
    font-size: ${lrem(13)};
    margin-top: ${lrem(30)};
    padding: ${lrem(10)};
  }
`

const BackButton = styled(SendButton)`
  background-color: transparent;
  color: var(--main-color);
`

const CompleteText = styled.div`
  grid-column: 4 / 9;
  font-size: ${lrem(25)};
  letter-spacing: 0.05em;
  line-height: 2.25;
  text-align: center;
  @media (max-width: 40rem){
    font-size: ${lrem(12)};
  }
`

const objectToURLSearchFormData = (data: Record<string, string>) => {
  const formData = new FormData()
  Object.entries(data).forEach(([key, value]) => formData.append(key, value))
  return formData
}

type ContactFormProps = {

}
export const ContactForm: FunctionComponent<ContactFormProps> = () => {
  const outerRef = createRef<HTMLDivElement>()
  const [companyName, setCompanyName] = useState('')
  const [department, setDepartment] = useState('')
  const [name, setName] = useState('')
  const [address, setAddress] = useState('')
  const [emailAddress, setEmailAddress] = useState('')
  const [confirmEmailAddress, setConfirmEmailAddress] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [message, setMessage] = useState('')
  
  const [confirming, setConfirming] = useState(false)
  const [sent, setSent] = useState(false)
  const [sending, setSending] = useState(false)

  const confirm = () => {
    if(emailAddress !== confirmEmailAddress){
      alert('「メールアドレス」と「メールアドレス確認用」が一致しません入力内容をご確認ください。')
      return
    }
    if(![companyName, name, address, emailAddress, phoneNumber, message].every(v => v.length > 0)){
      alert('必須項目をご入力ください')
      return
    }
    outerRef.current?.scrollIntoView({inline: 'start'})
    setConfirming(true)
  }

  const send = async () => {
    try {
      const res = await fetch('https://www.marine-soft.co.jp/api/index.php?rest_route=/contact-form-7/v1/contact-forms/9/feedback', {
        method: 'POST',
        body: objectToURLSearchFormData({
          'company-name': companyName,
          'department': department,
          'empolyee-name': name,
          'address': address,
          'email': emailAddress,
          'tel': phoneNumber,
          'message': message
        })
      })
    } catch(err){
      alert('送信に失敗しました。しばらくしてからもう一度お試しください')
      setSending(false)
      return
    }
    setSending(false)
    setSent(true)
  }

  return <Outer className={classNames({confirming})} ref={outerRef}>
    {
      sent ?
      <CompleteText>送信完了しました</CompleteText> :
      <>
        <Container>
          <Label1>会社名<Required>必須</Required></Label1>
          <Label2>Company name<Required>Required</Required></Label2>
          <Input type="text" name="company-name" value={companyName} onChange={e => setCompanyName(e.currentTarget.value)} disabled={confirming}/>
        </Container>
        <Container>
          <Label1>部署名</Label1>
          <Label2>Department</Label2>
          <Input type="text" name="department" value={department} onChange={e => setDepartment(e.currentTarget.value)} disabled={confirming}/>
        </Container>
        <Container>
          <Label1>お名前<Required>必須</Required></Label1>
          <Label2>Your name<Required>Required</Required></Label2>
          <Input type="text" name="empolyee-name" value={name} onChange={e => setName(e.currentTarget.value)} disabled={confirming}/>
        </Container>
        <Container>
          <Label1>ご住所<Required>必須</Required></Label1>
          <Label2>Address<Required>Required</Required></Label2>
          <Input type="text" name="address" value={address} onChange={e => setAddress(e.currentTarget.value)} disabled={confirming}/>
        </Container>
        <Container>
          <Label1>メールアドレス<Required>必須</Required></Label1>
          <Label2>Email Address<Required>Required</Required></Label2>
          <Input type="email" name="email" value={emailAddress} onChange={e => setEmailAddress(e.currentTarget.value)} disabled={confirming}/>
        </Container>
        <Container>
          <Label1>メールアドレス確認用<Required>必須</Required></Label1>
          <Label2>Email address ( For confirmation )<Required>Required</Required></Label2>
          <Input type="email" value={confirmEmailAddress} onChange={e => setConfirmEmailAddress(e.currentTarget.value)} disabled={confirming}/>
        </Container>
        <Container>
          <Label1>電話番号<Required>必須</Required></Label1>
          <Label2>Phone number<Required>Required</Required></Label2>
          <Input type="tel" name="phone-number" value={phoneNumber} onChange={e => setPhoneNumber(e.currentTarget.value)} disabled={confirming}/>
        </Container>
        <Container>
          <Label1>お問合せ内容<Required>必須</Required></Label1>
          <Label2>Inquiry<Required>Required</Required></Label2>
          <TextareaContainer>
            <TextareaSizer>{message}</TextareaSizer>
            <Textarea value={message} name="message" onChange={e => setMessage(e.currentTarget.value)} disabled={confirming}/>
          </TextareaContainer>
        </Container>
        <ButtonContainer>
          {
            confirming ? <>
              <BackButton onClick={() => setConfirming(false)} disabled={sending}>
                修正する<br />
                EDIT
              </BackButton>
              <SendButton onClick={send} disabled={sending}>
                {
                  sending ? <>
                    送信中<br />
                    SENDING
                  </> : <>
                    送信する<br />
                    SEND
                  </>
                }
              </SendButton>
            </> : <>
              <SendButton onClick={confirm} disabled={sending}>
                入力内容を確認する<br />
                CONFIRM
              </SendButton>
            </>
          }
        </ButtonContainer>
      </>
    }
  </Outer>
}