import { css } from 'styled-components';

export const pageMaxWidth = css`
  max-width: var(--page-max-width);
  margin-left: auto;
  margin-right: auto;
`

export const pageMaxContentWidth = css`
  max-width: var(--page-max-content-width);
  padding-left: var(--page-margin);
  padding-right: var(--page-margin);
  margin-left: auto;
  margin-right: auto;
`