import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { lrem } from '../styles/lrem'

const buttonStyles = css`
  display: block;
  background-color: var(--main-color);
  font-size: ${lrem(30)};
  letter-spacing: 0.025em;
  font-weight: 700;
  color: white;
  padding: ${lrem(30)} ${lrem(45)};
  min-width: max(min-content, ${lrem(360)});
  text-align: center;
  width: min-content;
  white-space: nowrap;
  box-sizing: border-box;
  @media (max-width: 40rem){
    width: 100%;
    padding: ${lrem(10)};
    font-size: ${lrem(15)};
  }
`

export const Button = styled.div`
  ${buttonStyles}
`

export const LinkButton = styled(Link)`
  ${buttonStyles}
`
