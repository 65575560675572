import React, { Fragment, FunctionComponent } from 'react'
import styled from 'styled-components'
import { lrem } from '../styles/lrem'
import { useCompanyProfileQuery } from '../generated/graphql'

const Outer = styled.div`
  background-color: #ECF6F9;
  padding-top: ${lrem(225)};
  @media (max-width: 40rem){
    padding-top: ${lrem(60)};
    padding-bottom: ${lrem(60)};
  }
`

const Inner = styled.div`
  max-width: var(--page-max-width);
  margin-left: auto;
  margin-right: auto;
  padding-left: calc(var(--page-side-margin1) + var(--page-side-margin2));
  padding-right: calc(var(--page-side-margin1));
  box-sizing: border-box;
`

const Head2 = styled.div`
  font-size: ${lrem(60)};
  letter-spacing: 0.025em;
  border-bottom: ${lrem(10)} solid var(--main-color);
  color: var(--main-color);
  padding-bottom: ${lrem(30)};
  margin-bottom: ${lrem(130)};
  @media (max-width: 40rem){
    font-size: ${lrem(25)};
    text-align: center;
    border-bottom-width: ${lrem(5)};
    padding-bottom: ${lrem(21)};
    margin-bottom: ${lrem(50)};
    margin-left: var(--page-margin);
    margin-right: var(--page-margin);
  }
  small {
    margin-left: ${lrem(40)};
    font-size: ${lrem(25)};
    @media (max-width: 40rem){
      font-size: ${lrem(12)};
      margin-left: 0;
      display: block;
      margin-top: ${lrem(10)};
    }
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: ${lrem(260)} 1fr;
  @media (max-width: 40rem){
    grid-template-columns: min-content 1fr;
    margin-left: var(--page-margin);
    margin-right: var(--page-margin);
  }
  & + & {
    margin-top: ${lrem(40)};
    padding-top: ${lrem(40)};
    border-top: ${lrem(3)} solid currentColor;
    @media (max-width: 40rem){
      border-top: 0 none;
      margin-top: 0;
    }
  }
`

const GridCell = styled.div`
  font-size: ${lrem(23)};
  line-height: 1.73913043478;
  color: #707070;
  padding-top: ${lrem(12)};
  padding-bottom: ${lrem(12)};
  white-space: pre-wrap;
  @media (max-width: 40rem){
    font-size: ${lrem(9)};
    padding-top: ${lrem(4)};
    padding-bottom: ${lrem(4)};
    :nth-child(2n + 1){
      white-space: nowrap;
      padding-right: ${lrem(10)};
    }
  }
  :nth-child(n + 3){
    border-top: 1px solid currentColor;
  }
`

const GridCellJustify = styled(GridCell)`
  padding-right: ${lrem(130)};
  display: flex;
  justify-content: space-between;
  span {
    
  }
`

const GridCellEn = styled(GridCell)`
  line-height: 1.73913043478;
  padding-top: ${lrem(12)};
  padding-bottom: ${lrem(12)};
  @media (max-width: 40rem){
    padding-top: ${lrem(4)};
    padding-bottom: ${lrem(4)};
  }
`

type AboutSection2Props = {

}
export const AboutSection2: FunctionComponent<AboutSection2Props> = () => {
  const companyProfileQuery = useCompanyProfileQuery()
  return <Outer>
    <Inner>
      <Head2>
        COMPANY PROFILE
        <small>
          ［会社概要］
        </small>
      </Head2>
      <Grid>
        {
          companyProfileQuery.data?.page?.companyProfileFields?.ja?.map((row, i) => {
            return <Fragment key={i}>
              <GridCellJustify>
                {row?.label?.split('').map((str, i) => {
                  return <span key={i}>{str}</span>
                })}
              </GridCellJustify>
              <GridCell>{row?.value}</GridCell>
            </Fragment>
          })
        }
      </Grid>
      <Grid>
        {
          companyProfileQuery.data?.page?.companyProfileFields?.en?.map((row, i) => {
            return <Fragment key={i}>
              <GridCellEn>{row?.label}</GridCellEn>
              <GridCellEn>{row?.value}</GridCellEn>
            </Fragment>
          })
        }
      </Grid>
    </Inner>
  </Outer>
}