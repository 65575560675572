import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { TopHead } from '../components/top-head'
import { pageGrid } from '../styles/page-grid'
import { lrem } from '../styles/lrem'

const Outer = styled.div`
  padding-top: ${lrem(181)};
  @media (max-width: 40rem){
    padding-top: calc(${lrem(44)} + var(--header-height));
  }
`

const Inner1 = styled.div`
  ${pageGrid}
`

const Head1 = styled.div`
  font-size: ${lrem(50)};
  letter-spacing: 0.05em;
  color: var(--main-color);
  grid-column: 4 / 7;
  font-weight: 700;
  margin-bottom: ${lrem(60)};
  @media (max-width: 40rem){
    font-size: ${lrem(15)};
    margin-top: ${lrem(15)};
    grid-column: 4 / 9;
    text-align: center;
    margin-bottom: ${lrem(40)};
  }
`

const Text1 = styled.div`
  grid-column: 4 / -1;
  font-size: ${lrem(25)};
  letter-spacing: 0.05em;
  line-height: 2.25;
  @media (max-width: 40rem){
    font-size: ${lrem(12)};
    letter-spacing: -0.025em;
  }
`

type VoicesSection1Props = {

}
export const VoicesSection1: FunctionComponent<VoicesSection1Props> = () => {
  return <Outer>
    <TopHead>VOICE</TopHead>
    <Inner1>
      <Head1>
        スタッフの思いを声にして
      </Head1>
      <Text1>
        マリンソフトで活躍するスタッフの思いをご紹介します。
      </Text1>
    </Inner1>
  </Outer>
}