import React, { FunctionComponent, ReactNode } from 'react'
import styled from 'styled-components'
import { lrem } from '../styles/lrem'
import { pageMaxContentWidth } from '../styles/page-max-width'

const Outer = styled.div`
  position: relative;
  ${pageMaxContentWidth}
`

const Inner = styled.div`
  writing-mode: vertical-rl;
  position: absolute;
  display: flex;
  align-items: center;
  gap: ${lrem(50)};
  width: 0;
  margin: 0 var(--page-side-margin1);
  color: var(--main-color);
  z-index: 1;
  @media (max-width: 40rem){
    position: static;
    writing-mode: unset;
    justify-content: center;
    margin: 0;
    width: auto;
  }
`

const Text = styled.div`
  font-size: ${lrem(80)};
  font-weight: 700;
  white-space: nowrap;
  letter-spacing: 0.025em;
  @media (max-width: 40rem){
    font-size: ${lrem(20)};
  }
`

const Bar = styled.div`
  height: ${lrem(327)};
  width: ${lrem(10)};
  background-color: currentColor;
  @media (max-width: 40rem){
    display: none;
  }
`

type TopHeadProps = {
  children: ReactNode
  withBar?: boolean
  position?: 'left' | 'right'
  color?: string
}
export const TopHead: FunctionComponent<TopHeadProps> = ({children, withBar, position, color}) => {
  return <Outer>
    <Inner style={{[position ?? 'left']: 0, color}}>
      <Text>{children}</Text>
      {
        withBar && <Bar />
      }
    </Inner>
  </Outer>
}