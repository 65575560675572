import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { TopHead } from '../components/top-head'
import { lrem } from '../styles/lrem'
import { pageGrid } from '../styles/page-grid'

const Outer = styled.div`
  background-image: url(/images/0013.jpg);
  background-size: cover;
  padding-top: ${lrem(181)};
  padding-bottom: ${lrem(289)};
  @media (max-width: 40rem){
    padding-top: calc(${lrem(44)} + var(--header-height));
    padding-bottom: ${lrem(108)};
  background-image: url(/images/0023.jpg);
  }
`

const Inner1 = styled.div`
  ${pageGrid}
`

const Head1 = styled.div`
  font-size: ${lrem(50)};
  letter-spacing: 0.05em;
  color: var(--main-color);
  grid-column: 4 / 6;
  font-weight: 700;
  margin-bottom: ${lrem(200)};
  @media (max-width: 40rem){
    font-size: ${lrem(15)};
    margin-bottom: ${lrem(80)};
    margin-top: ${lrem(15)};
    grid-column: 4 / 9;
    text-align: center;
  }
`

const Inner2 = styled.div`
  grid-row: 2;
  grid-column: 2 / 10;
  display: flex;
  justify-content: center;
`

const Inner3 = styled.div`
`

const Head2 = styled.div`
  font-size: ${lrem(130)};
  color: white;
  font-family: 'Lato';
  font-weight: 500;
  @media (max-width: 40rem){
    font-size: ${lrem(30)}; 
  }
  span {
    color: var(--main-color);
    font-size: ${lrem(200)};
    @media (max-width: 40rem){
      font-size: ${lrem(50)};
    }
  }
`

const Text1 = styled.div`
  font-size: ${lrem(60)};
  letter-spacing: 0.05em;
  line-height: 1.66;
  color: white;
  margin-top: ${lrem(55)};
  @media (max-width: 40rem){
    font-size: ${lrem(18)};
  }
`

const Text2 = styled.div`
  font-size: ${lrem(35)};
  letter-spacing: 0.05em;
  line-height: 2;
  color: white;
  margin-top: ${lrem(55)};
  @media (max-width: 40rem){
    font-size: ${lrem(15)};
  }
`

type FeaturesSection1Props = {

}
export const FeaturesSection1: FunctionComponent<FeaturesSection1Props> = () => {
  return <Outer>
    <TopHead>FEATURES</TopHead>
    <Inner1>
      <Head1>マリンソフトの特長</Head1>
      <Inner2>
        <Inner3>
          <Head2>Our Values = <span>3S</span></Head2>
          <Text1>
            マリンソフトの特長は<br />
            3つの［S］に集約されています。
          </Text1>
          <Text2>
            The features of Marine soft Co., Ltd. are summarized in three [S].
          </Text2>
        </Inner3>
      </Inner2>
    </Inner1>
  </Outer>
}